import { TableHeaderCategoryConfig } from "../../../../../components/tables/tableContext/TableContext";
import { ScheduleWorkingPointsListInterface } from "../../../../../redux/interfaces/expedition.interface";

export const expeditionScheduleWorkingPointsTableHeader: TableHeaderCategoryConfig[] =
  [
    {
      label: "Cod intern",
      value: "cod_intern",
      minW: 125,
    },
    {
      label: "Ruta",
      value: "route",
      minW: 125,
      withSearch: true,
    },
    {
      value: "Adresa",
      minW: 125,
    },
    {
      value: "deliveryPoint",
      label: "Locatie de livrare",
      withSearch: true,
      minW: 125,
    },
    {
      value: "Stare",
      minW: 125,
    },
  ];

export const getExpeditionScheduleWorkingPointsTableData: any = (
  data: ScheduleWorkingPointsListInterface[],
) =>
  data?.map((item) => {
    return {
      id: item.id_punctlucru,
      crudData: item,
      data: [
        {
          title: item.cod_intern ?? "-",
        },
        {
          title: item.ruta,
        },
        {
          title: `${item.judet} ${item.localitate}`,
          subtitle: item.adresa,
        },
        {
          title: item.denumire,
        },
        {
          badges: [
            {
              badgeText: item.status ? "Validat" : "Nevalidat",
              badgeColor: item.status ? "light-success" : "light-danger",
            },
          ],
        },
      ],
    };
  });
