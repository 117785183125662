import { createSlice } from "@reduxjs/toolkit";
import {
  ActiveTransportsInterface,
  CargoNodeInterface,
  SearchInvoiceInterface,
} from "../interfaces/raports.interface";
import {
  fetchActiveCargoCars,
  fetchActiveTransports,
  fetchCargoCar,
  fetchCargoNode,
  fetchSearchInvoice,
} from "../api/raports.api";
import { FleetInterface } from "../interfaces/fleet.interface";

export const defaultStateRaports: {
  searchInvoice: SearchInvoiceInterface;
  activeTransports: ActiveTransportsInterface;
  cargoNode: CargoNodeInterface;
  activeCargoCars: FleetInterface;
} = {
  searchInvoice: {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: "",
  },
  activeTransports: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  cargoNode: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  activeCargoCars: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
};

const raports = createSlice({
  name: "raports",
  initialState: defaultStateRaports,
  reducers: {
    clearCargoNode: (state) => {
      return {
        ...state,
        cargoNode: {
          ...defaultStateRaports.cargoNode,
        },
      };
    },
  },
  extraReducers: (builder) => {
    // fetchSearchInvoice  raport start
    builder.addCase(fetchSearchInvoice.pending, (state) => {
      return {
        ...state,
        searchInvoice: {
          ...defaultStateRaports.searchInvoice,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchSearchInvoice.rejected, (state, action: any) => {
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchSearchInvoice.fulfilled, (state, action) => {
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // fetchSearchInvoice  raport end

    // fetch active transports start
    builder.addCase(fetchActiveTransports.pending, (state) => {
      return {
        ...state,
        activeTransports: {
          ...defaultStateRaports.activeTransports,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchActiveTransports.rejected, (state, action: any) => {
      return {
        ...state,
        activeTransports: {
          ...state.activeTransports,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchActiveTransports.fulfilled, (state, action) => {
      return {
        ...state,
        activeTransports: {
          ...state.activeTransports,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // fetch active transports end

    // fetch cargo node start
    builder.addCase(fetchCargoNode.pending, (state) => {
      return {
        ...state,
        cargoNode: {
          ...defaultStateRaports.cargoNode,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchCargoNode.rejected, (state, action: any) => {
      return {
        ...state,
        cargoNode: {
          ...state.cargoNode,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchCargoNode.fulfilled, (state, action) => {
      return {
        ...state,
        cargoNode: {
          ...state.cargoNode,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // fetch cargo node end

    // fetch cargo car start
    builder.addCase(fetchCargoCar.pending, (state) => {
      return {
        ...state,
        cargoNode: {
          ...defaultStateRaports.cargoNode,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchCargoCar.rejected, (state, action: any) => {
      return {
        ...state,
        cargoNode: {
          ...state.cargoNode,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchCargoCar.fulfilled, (state, action) => {
      return {
        ...state,
        cargoNode: {
          ...state.cargoNode,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // fetch cargo car end

    // fetch active cargo start
    builder.addCase(fetchActiveCargoCars.pending, (state) => {
      return {
        ...state,
        activeCargoCars: {
          ...defaultStateRaports.activeCargoCars,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchActiveCargoCars.rejected, (state, action: any) => {
      return {
        ...state,
        activeCargoCars: {
          ...state.activeCargoCars,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchActiveCargoCars.fulfilled, (state, action) => {
      return {
        ...state,
        activeCargoCars: {
          ...state.activeCargoCars,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // fetch active cargo end
  },
});

export const { clearCargoNode } = raports.actions;

export default raports.reducer;
