import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../../helpers/apiHelper";
import {
  getServices,
  postActivateDeactivateService,
} from "../../../../../redux/api/admin.api";
import { useAppDispatch } from "../../../../../redux/hooks";
import { ServicesListInterface } from "../../../../../redux/interfaces/admin.interface";
import { adminRoutes } from "../../../../../router/routesConstants";

function ServicesActionButtons({
  itemId,
  crudData,
  tableContext,
}: {
  itemId: string;
  crudData: ServicesListInterface;
  tableContext?: any;
}) {
  const dispatch = useAppDispatch();

  const { status, denumire } = crudData;

  const handleActivateOrDeactivateService = async () => {
    const resultAction = await dispatch(
      postActivateDeactivateService({ idServiciu: itemId }),
    );

    if (
      checkEndpointStatus(resultAction, postActivateDeactivateService) ===
      endpointStatus.fulfilled
    ) {
      if (
        tableContext.tablePagination.page &&
        tableContext.tablePagination.rowsPerPage
      ) {
        dispatch(
          getServices({
            page: tableContext.tablePagination.page,
            perPage: tableContext.tablePagination.rowsPerPage,
          }),
        );
      }
    }
  };

  const activateDeactivateModalTitle =
    status === 1 ? "Dezactiveaza serviciu" : "Activeaza";

  const activateDeactivateModalSubtitle =
    status === 1
      ? `Doriti sa dezactivati serviciul "${denumire}"?`
      : `Doriti sa activati serviciul "${denumire}"?`;

  return (
    <div>
      <CustomButton
        tooltipTitle="Editeaza"
        variant="contained"
        className="mt-1 me-1"
        navigate={`/${adminRoutes.services}/${itemId}`}>
        <SvgIcon type="EDIT" />
      </CustomButton>

      <CustomButton
        tooltipTitle={`${status === 1 ? "Dezactivati" : "Activati"}`}
        variant="contained"
        color={status === 1 ? "error" : "success"}
        className="mt-1 me-1"
        withConfirmationModal={{
          modalTitle: activateDeactivateModalTitle,
          modalSubtitle: activateDeactivateModalSubtitle,
          modalLeftButtonVariant: "outlined",
          modalRightButtonOnClick: handleActivateOrDeactivateService,
        }}>
        <SvgIcon
          type={status === 1 ? "LOCK_CLOSE" : "LOCK_OPEN"}
          className="svg-icon-2"
          fill="white"
        />
      </CustomButton>
    </div>
  );
}

export default ServicesActionButtons;
