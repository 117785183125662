import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import TableWidget from "../../../components/tables/TableWidget";
import FormHeader from "../../../components/misc/FormHeader";
import { getServices } from "../../../redux/api/admin.api";
import {
  getServicesTableData,
  servicesTableHeader,
} from "./components/tableConfigs/servicesAdminTableConfig";
import { adminRoutes, crudRoutes } from "../../../router/routesConstants";
import ServicesActionButtons from "./components/TableActionButtonsComponent/ServicesActionButtons";

function ServicesAdmin() {
  const dispatch = useAppDispatch();

  const {
    admin: {
      servicesAdmin: {
        data: { total, data: servicesAdmin },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    admin: state.admin,
  }));

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      dispatch(
        getServices({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
        }),
      );
    }
  };

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <FormHeader
          title="Servicii"
          buttonRoute={`/${adminRoutes.services}/${crudRoutes.create}`}
          buttonIcon="ADD"
        />
      )}
      tableHeaderData={servicesTableHeader}
      tableItemsData={getServicesTableData(servicesAdmin)}
      totalItems={total}
      borderedRow
      tableLoading={isLoading}
      ActionButtonsComponent={(props: any) => (
        <ServicesActionButtons {...props} />
      )}
      handleTableActions={handleTableActions}
    />
  );
}

export default ServicesAdmin;
