import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { FleetDataInterface } from "../../../../../redux/interfaces/fleet.interface";

export const cargoCarTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Nr. auto",
    value: "nrauto",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Marca",
    value: "marca",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Model",
    value: "model",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Nr. marfa",
    value: "marfa",
    minW: 125,
  },
];

export const getCargoCarTableData = (data: FleetDataInterface[]): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_auto,
      crudData: item,
      data: [
        {
          title: item.nrauto,
        },
        {
          title: item.marca,
        },
        {
          title: item.model,
        },
        {
          badges: [
            {
              badgeText: `Binuri x${item.nr_binuri ?? 0}`,
              badgeColor: "light-info",
            },
            {
              badgeText: `Colete x${item.nr_colete ?? 0}`,
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });
