import { memo } from "react";
import { TableItemId } from "../../../../../components/tables/TableWidget";
import CustomButton from "../../../../../components/CustomButton";
import { KTSVG } from "../../../../../helpers/KTSVG";
import { TableItemType } from "../../../../../components/tables/tableContext/TableContext";
import { expeditionRoutes } from "../../../../../router/routesConstants";
import { ScheduleWorkingRoutesListInterface } from "../../../../../redux/interfaces/expedition.interface";

function ExpeditionScheduleWorkingRoutesTableActionButtons({
  itemId,
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: ScheduleWorkingRoutesListInterface;
  tableContext?: any;
}) {
  const goToOrganizeWorkingPoint = () => {
    return `/${expeditionRoutes.routesOrganizationLocal}/${expeditionRoutes.organize}?id=${itemId}&denumire=${crudData?.denumire}&cod=${crudData?.cod}`;
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        tooltipTitle="Organizare"
        className="mx-1 mb-1"
        navigate={goToOrganizeWorkingPoint()}>
        <KTSVG
          path="/media/svg/reception/document.svg"
          className="svg-icon-1"
          fill="white"
        />
      </CustomButton>
    </div>
  );
}

export default memo(ExpeditionScheduleWorkingRoutesTableActionButtons);
