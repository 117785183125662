import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";

import CustomButton from "../../components/CustomButton";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { removeUserToken } from "../../redux/reducer/auth.reducer";
import { firstLetterToUpperCase } from "../../helpers/stringFormater";
import SvgIcon from "../../helpers/SvgIcon";

const menuId = "primary-search-account-menu";
const mobileMenuId = "primary-search-account-menu-mobile";

const NavbarMenuDesktop = ({
  handleProfileMenuOpen,
  handleMobileMenuOpen,
}: {
  handleProfileMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleMobileMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
}) => {
  const {
    user: { username },
  } = useAppSelector((state) => ({
    user: state.user,
  }));

  return (
    <>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          tabIndex={-1}
          onClick={() => {
            return;
          }}>
          <Badge badgeContent={17} color="error">
            <SvgIcon type="NOTIFICATIONS_ICON" />
          </Badge>
        </IconButton>

        <CustomButton onClick={handleProfileMenuOpen}>
          {firstLetterToUpperCase(username)}
        </CustomButton>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          tabIndex={-1}
          onClick={handleMobileMenuOpen}
          color="inherit">
          <SvgIcon type="MORE_ICON" />
        </IconButton>
      </Box>
    </>
  );
};

const NavbarSubItem = ({
  anchorEl,
  isMenuOpen,
  handleMenuClose,
}: {
  anchorEl: HTMLElement | null;
  isMenuOpen: boolean;
  handleMenuClose: () => void;
}) => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(removeUserToken());
    handleMenuClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem onClick={handleLogout}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit">
          <SvgIcon type="LOGOUT_ICON" />
        </IconButton>
        Logout
      </MenuItem>
    </Menu>
  );
};

const NavbarMenuMobile = ({
  mobileMoreAnchorEl,
  isMobileMenuOpen,
  handleMobileMenuClose,
}: {
  mobileMoreAnchorEl: HTMLElement | null;
  isMobileMenuOpen: boolean;
  handleMobileMenuClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const {
    user: { username },
  } = useAppSelector((state) => ({
    user: state.user,
  }));

  const handleLogout = () => {
    dispatch(removeUserToken());
    handleMobileMenuClose();
  };

  return (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem disabled>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <SvgIcon type="ACCOUNT_CIRCLE" />
        </IconButton>

        {firstLetterToUpperCase(username)}
      </MenuItem>
      <Divider />

      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit">
          <Badge badgeContent={17} color="error">
            <SvgIcon type="NOTIFICATIONS_ICON" />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit">
          <SvgIcon type="LOGOUT_ICON" />
        </IconButton>
        Logout
      </MenuItem>
    </Menu>
  );
};

const NavbarNavigationItems = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="d-flex align-items-center">
      <NavbarMenuDesktop
        handleProfileMenuOpen={handleProfileMenuOpen}
        handleMobileMenuOpen={handleMobileMenuOpen}
      />

      <NavbarMenuMobile
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        isMobileMenuOpen={isMobileMenuOpen}
        handleMobileMenuClose={handleMobileMenuClose}
      />
      <NavbarSubItem
        anchorEl={anchorEl}
        isMenuOpen={isMenuOpen}
        handleMenuClose={handleMenuClose}
      />
    </Box>
  );
};

export default NavbarNavigationItems;
