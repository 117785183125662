import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../../components/tables/tableContext/TableContext";
import { CargoNodeDataInterface } from "../../../../../../redux/interfaces/raports.interface";

export const cargoTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Sn bin/AWB",
    value: "sn_bin",
    minW: 125,
  },
  {
    label: "Nod destinatie",
    value: "nod_destinatie_bin",
    minW: 125,
  },
  {
    label: "Punct de livrare",
    value: "pctLivrare_colet",
    minW: 125,
  },
  {
    label: "Tip",
    value: "tip",
    minW: 125,
  },
];

export const getCargoTableData = (
  data: CargoNodeDataInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_nod_colet,
      crudData: item,
      data: [
        {
          title: item.sn_bin ?? item.doc_trans ?? "-",
        },
        {
          title: item.nod_destinatie_bin ?? item.nod_destinatie_colet ?? "-",
        },
        {
          title: item.pctLivrare_colet ?? "-",
        },
        {
          badges: [
            {
              badgeText: item.sn_bin ? "Bin" : "Colet",
              badgeColor: item.sn_bin ? "light-info" : "light-primary",
            },
          ],
        },
      ],
    };
  });
