import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { CargoNodeDataInterface } from "../../../../../redux/interfaces/raports.interface";

export const cargoNodeTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Nr. BIN/Expeditie",
    value: "sn_bin",
    minW: 125,
  },
  {
    label: "Nod destinatie",
    value: "nod_destinatie_bin",
    minW: 125,
  },
  {
    label: "Punct de livrare",
    value: "pctLivrare_colet",
    minW: 125,
  },
  {
    label: "Tip",
    value: "tip",
    minW: 125,
  },
];

export const getCargoNodeTableData = (
  data: CargoNodeDataInterface[],
): TableData[] =>
  data.map((item) => {
    const wrongDestination =
      !item.sn_bin && item.id_nod !== item.id_nod_destinatie_colet;
    const wrongDestinationColor = "red";
    return {
      id: item.id_nod_colet,
      crudData: item,
      data: [
        {
          title: item.sn_bin ?? item.doc_trans ?? "-",
          titleStyle: { color: wrongDestination && wrongDestinationColor },

          subtitle: wrongDestination
            ? "Coletul nu se afla in nodul destinatie corect"
            : undefined,
        },
        {
          title: item.nod_destinatie_bin ?? item.nod_destinatie_colet ?? "-",
        },
        {
          title: item.pctLivrare_colet ?? "-",
        },
        {
          badges: [
            {
              badgeText: item.sn_bin ? "Bin" : "Colet",
              badgeColor: item.sn_bin ? "light-info" : "light-primary",
            },
          ],
        },
      ],
    };
  });
