import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiRequest,
  errorFormatHandler,
  isDevelop,
} from "../../helpers/apiHelper";

const apiEnv = isDevelop
  ? process.env.REACT_APP_TEST_API_URL
  : process.env.REACT_APP_PUBLIC_API_URL;

export const fetchSearchInvoice = createAsyncThunk(
  "/raports/cautaFacturaAwb",
  async (
    params: {
      factura: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data: responseData } = await apiRequest(
        "/cautaFacturaAwb",
        "POST",
        params,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/raports/cautaFacturaAwb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchDownloadTemperatureRaport = createAsyncThunk(
  "/raports/fetchDownloadTemperatureRaport",
  async (
    {
      idRaport,
    }: {
      idRaport: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window
        .open(`${apiEnv}/downloadTemperatureRaport/${idRaport}`, "_blank")
        ?.focus();
    } catch (err: any) {
      console.warn("/raports/fetchDownloadTemperatureRaport", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchActiveTransports = createAsyncThunk(
  "/raports/fetchActiveTransports",
  async (
    params: {
      page?: number;
      perPage?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/getTransportsRaports", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/raports/fetchActiveTransports", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchDownloadCmr = createAsyncThunk(
  "/raports/fetchDownloadCmr",
  async (
    {
      idTransport,
    }: {
      idTransport?: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window.open(`${apiEnv}/descarcaCMR/${idTransport}`, "_blank")?.focus();
    } catch (err: any) {
      console.warn("/raports/fetchDownloadCmr", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchCargoNode = createAsyncThunk(
  "/raports/fetchCargoNode",
  async (
    params: {
      page?: number;
      perPage?: number;
      idNod?: string | number | undefined;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/showCargoInNod", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/raports/fetchCargoNode", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchCargoCar = createAsyncThunk(
  "/raports/fetchCargoCar",
  async (
    params: {
      page?: number;
      perPage?: number;
      idAuto?: string | number | undefined;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/showCargoInCar", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/raports/fetchCargoCar", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchActiveCargoCars = createAsyncThunk(
  "/raports/fetchActiveCargoCars",
  async (
    params: {
      page?: number;
      perPage: number;
      nrauto?: string;
      marca?: string;
      model?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest(`/displayActiveCars`, "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/raports/fetchActiveCargoCars", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchDownloadTemperatureChart = createAsyncThunk(
  "/raports/fetchDownloadTemperatureChart",
  async (
    {
      nrAuto,
      dataCursa,
    }: {
      nrAuto?: string | number | null;
      dataCursa?: string | Date | null;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window
        .open(
          `${apiEnv}/downloadAutoTemperatureRaport?nrAuto=${nrAuto}&dataCursa=${dataCursa}`,
          "_blank",
        )
        ?.focus();
    } catch (err: any) {
      console.warn("/raports/fetchDownloadTemperatureChart", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
