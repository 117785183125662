import React, { useState } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";

import ContentCard from "../../../components/misc/ContentCard";
import TextInput from "../../../components/misc/TextInput";
import DatePicker from "../../../components/misc/DatePicker";
import moment from "moment";
import FormButtons from "../../../components/misc/FormButtons";
import { fetchDownloadTemperatureChart } from "../../../redux/api/raports.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import { dateForDatabase } from "../../../utils/dateAndTime";

function VehicleTemperatureChart() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

  const [nrAuto, setNrAuto] = useState<string | number | null>(null);
  const [transportDay, setTransportDay] = useState<Date | null>(
    new Date(yesterday),
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleDownloadTemperatureChart = async () => {
    if (!nrAuto) {
      return;
    }

    setIsLoading(true);

    const resultAction = await dispatch(
      fetchDownloadTemperatureChart({
        nrAuto: nrAuto.toString().toUpperCase(),
        dataCursa: dateForDatabase(transportDay),
      }),
    );

    if (
      checkEndpointStatus(resultAction, fetchDownloadTemperatureChart) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, fetchDownloadTemperatureChart) ===
      endpointStatus.fulfilled
    ) {
      setNrAuto(null);
    }
  };

  const validNrAuto = nrAuto?.toString().length;

  const disableSubmit =
    (validNrAuto && (validNrAuto < 6 || validNrAuto > 8)) || !nrAuto;

  return (
    <ContentCard
      cardTitle={"Descarca diagrama de temperatura pentru un autovehicul"}
      cardHeaderClassName="text-center"
      isLoading={isLoading}>
      <div>
        <TextInput
          label={"Nr. auto"}
          name={"nrAuto"}
          type="text"
          value={nrAuto}
          onChange={(value) => setNrAuto(value)}
          withVerticalSpacer
        />

        <DatePicker
          label="Data incepere"
          maxDate={new Date(yesterday)}
          value={transportDay}
          handleChangeDate={(value) => setTransportDay(value)}
        />
      </div>

      <FormButtons
        goBackLabel="Inapoi"
        goNextLabel="Descarca diagrama"
        nextButtonDisabled={disableSubmit}
        handleGoBack={handleGoBack}
        handleGoNext={handleDownloadTemperatureChart}
      />
    </ContentCard>
  );
}

export default VehicleTemperatureChart;
