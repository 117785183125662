import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  fetchFleetTypes,
  fetchShowDocs,
  fetchAddOrUpdateDocument,
} from "../../../redux/api/fleet.api";
import {
  clearFleetDocumentData,
  clearFleetDocuments,
  clearFleetTypeData,
} from "../../../redux/reducer/fleet.reducer";

import useRoutesHelper from "../../../hooks/useRoutesHelper";
import ContentCard from "../../../components/misc/ContentCard";
import Dropdown from "../../../components/misc/Dropdown";
import DatePicker from "../../../components/misc/DatePicker";
import TextInput from "../../../components/misc/TextInput";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import ExistingDocumentsWrapper from "./components/ExistingDocumentsWrapper";
import FormButtons from "../../../components/misc/FormButtons";
import { isBeforeToday } from "../../../utils/dateAndTime";

interface initialValuesInterface {
  doc_obs: string;
  doc_ts_start: string;
  doc_ts_stop: string;
  nr_km?: number | null;
}

const initialValues: initialValuesInterface = {
  doc_obs: "",
  doc_ts_start: new Date().toISOString(),
  doc_ts_stop: new Date().toISOString(),
  nr_km: null,
};

const formikSchema = Yup.object({
  doc_ts_start: Yup.date()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Data incepere valabilitate este obligatorie"),

  doc_ts_stop: Yup.date()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Data sfarsit valabilitate este obligatorie")
    .min(
      Yup.ref("doc_ts_start"),
      "Data sfarsit trebuie sa fie ulterioara datei de inceput",
    ),

  nr_km: Yup.number()
    .nullable()
    .typeError("Nr. de km trebuie sa contina doar cifre")
    .when("tip", {
      is: 21,
      then: Yup.number()
        .nullable()
        .required("Nr. de km este obligatoriu")
        .typeError("Nr. de km trebuie sa contina doar cifre"),
    }),
});

function FleetDocumentsEdit({ withUpdate }: { withUpdate?: boolean }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { routeMainParam, routeParams } = useRoutesHelper();
  const { carId } = routeMainParam as { carId: string };
  const { nrauto } = routeParams as { nrauto: string };

  const {
    fleet: {
      fleetDocumentData,
      fleetTypeData: { data: fleetTypesData, isLoading: fleetTypesLoading },
      fleetDocuments: {
        data: fleetDocumentsData,
        isLoading: fleetDocumentsLoading,
      },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  // Separate state for tip
  const [selectedTip, setSelectedTip] = useState<number | string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch fleet types and clear on unmount
  useEffect(() => {
    dispatch(fetchFleetTypes({ table: "nom_flota_doc" }));
    return () => {
      dispatch(clearFleetTypeData());
      dispatch(clearFleetDocumentData());
      dispatch(clearFleetDocuments());
    };
  }, [dispatch]);

  // Fetch documents when 'selectedTip' changes
  useEffect(() => {
    if (!withUpdate && carId && selectedTip) {
      dispatch(fetchShowDocs({ id: carId, tip_doc: selectedTip }));
    }
  }, [dispatch, withUpdate, carId, selectedTip]);

  // Pre-fill form when updating
  useEffect(() => {
    if (fleetTypesData.length && !selectedTip) {
      const initialTypeId = fleetTypesData[0].id;
      setSelectedTip(initialTypeId);

      if (fleetDocumentData && withUpdate) {
        setSelectedTip(fleetDocumentData.tip);
      }
      if (fleetDocumentData && withUpdate) {
        setFormikValues((prev) => ({
          ...prev,
          denumire: fleetDocumentData.denumire,
          doc_obs: fleetDocumentData.doc_obs,
          tip: fleetDocumentData.tip,
          valoare: fleetDocumentData.valoare,
          doc_ts_start: fleetDocumentData.doc_ts_start,
          doc_ts_stop: fleetDocumentData.doc_ts_stop,
          nr_km: fleetDocumentData.nr_km,
        }));
      }
    }
  }, [fleetDocumentData, fleetTypesData, withUpdate, selectedTip]);

  const handleSaveProductDetails = async (values: initialValuesInterface) => {
    setIsLoading(true);

    const resultAction = await dispatch(
      fetchAddOrUpdateDocument({
        ...fleetDocumentData,
        ...values,
        tip: selectedTip,
        id_auto: carId,
      }),
    );

    if (
      checkEndpointStatus(resultAction, fetchAddOrUpdateDocument) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, fetchAddOrUpdateDocument) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const fleetDocumentsWithoutExpired = useMemo(
    () =>
      fleetDocumentsData.filter((item) => {
        return !isBeforeToday(item.doc_ts_stop);
      }),
    [fleetDocumentsData],
  );

  const withShowExistingDocument = () => {
    const documentAlreadyExists = fleetDocumentsData.find(
      (item) => item.tip === selectedTip,
    );

    return documentAlreadyExists
      ? !isBeforeToday(documentAlreadyExists.doc_ts_stop)
      : false;
  };

  const withExistingDocuments = withShowExistingDocument();

  if (!fleetDocumentData && withUpdate) {
    return (
      <ContentCard>
        <h1>Document inexistent</h1>
      </ContentCard>
    );
  }

  const title =
    (withUpdate
      ? "Editeaza detalii document pentru masina"
      : "Adauga document pentru masina") + ` ${nrauto || ""}`;

  return (
    <>
      <ContentCard
        cardTitle={title}
        cardHeaderClassName="text-center"
        isLoading={fleetTypesLoading || isLoading}
        isLoadingClassName="d-flex flex-column justify-content-center align-items-center vh-75"
        CardHeader={() => <></>}>
        <Formik
          enableReinitialize
          initialValues={formikValues}
          validationSchema={formikSchema}
          onSubmit={handleSaveProductDetails}>
          {({
            isValid,
            errors,
            touched,
            values,
            getFieldProps,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            const selectedType = fleetTypesData?.find(
              (item) => item.id === selectedTip,
            );

            const isRevisionDoc = selectedTip === 21;

            return (
              <form
                className="form w-100"
                onSubmit={handleSubmit}
                noValidate
                id="kt_login_signin_form">
                <Dropdown
                  className="my-4"
                  title="Selecteaza document auto"
                  data={fleetTypesData}
                  singleValue={selectedType}
                  setSingleValue={(value) => {
                    setSelectedTip(value?.id || null);
                  }}
                  disabled={withUpdate}
                />

                <div className="d-md-flex d-block">
                  <DatePicker
                    label="Data incepere valabilitate"
                    value={values.doc_ts_start}
                    handleChangeDate={(newDate) => {
                      setFieldValue("doc_ts_start", newDate);
                    }}
                    error={errors["doc_ts_start"]}
                  />
                  <DatePicker
                    label="Data sfarsit valabilitate"
                    minDate={new Date(values.doc_ts_start)}
                    value={values.doc_ts_stop}
                    handleChangeDate={(newDate) => {
                      setFieldValue("doc_ts_stop", newDate);
                    }}
                    error={errors["doc_ts_stop"]}
                    withVerticalSpacer
                  />
                </div>

                {isRevisionDoc && (
                  <TextInput
                    type="text"
                    label="Nr. de km"
                    name="nr_km"
                    error={errors["nr_km"]}
                    touched={touched["nr_km"]}
                    inputProps={getFieldProps("nr_km")}
                  />
                )}
                <TextInput
                  type="text"
                  label="Observatii"
                  name="doc_obs"
                  error={errors["doc_obs"]}
                  touched={touched["doc_obs"]}
                  inputProps={getFieldProps("doc_obs")}
                />
                {withUpdate || !withExistingDocuments ? (
                  <FormButtons
                    goBackLabel="Inapoi"
                    goNextLabel={isSubmitting ? "Se incarca..." : "Salveaza"}
                    nextButtonDisabled={!isValid || isSubmitting}
                    nextButtonLoading={
                      isSubmitting ||
                      isLoading ||
                      fleetTypesLoading ||
                      fleetDocumentsLoading
                    }
                    handleGoBack={handleGoBack}
                  />
                ) : (
                  <ExistingDocumentsWrapper
                    fleetDocumentsWithoutExpired={fleetDocumentsWithoutExpired}
                    handleSubmit={handleSubmit}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </ContentCard>
    </>
  );
}

export default FleetDocumentsEdit;
