// withtailwind
import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import { useAppSelector } from "../redux/hooks";
import LoadingIndicator from "./misc/LoadingIndicator";

const CIRCULAR_PROGRESS_SIZE = 30;

const CIRCULAR_PROGRESS_FAKE_CONTAINER_STYLE = {
  width: CIRCULAR_PROGRESS_SIZE,
  height: CIRCULAR_PROGRESS_SIZE,
};

function GlobalLoading() {
  const { loadingGlobal } = useAppSelector((state) => ({
    loadingGlobal: state.globalComponents.loadingGlobal,
  }));

  return (
    <Modal
      open={loadingGlobal.open}
      aria-hidden="true"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 99999,
        backgroundColor: "rgba(0,0,0,0.2)",
      }}>
      <Box className="bg-white p-10 rounded-lg shadow-lg flex flex-row items-center justify-between w-auto max-w-lg">
        {loadingGlobal.loadingAlign === "left" ? (
          <LoadingIndicator size={CIRCULAR_PROGRESS_SIZE} />
        ) : (
          <div style={CIRCULAR_PROGRESS_FAKE_CONTAINER_STYLE} />
        )}

        <div>
          {loadingGlobal.title && (
            <Typography variant="h6" className="my-2 font-bold">
              {loadingGlobal.title}
            </Typography>
          )}
          {loadingGlobal.subtitle && (
            <Typography variant="body2" className="my-2 text-gray-500">
              {loadingGlobal.subtitle}
            </Typography>
          )}
        </div>

        {loadingGlobal.loadingAlign === "right" ? (
          <LoadingIndicator size={CIRCULAR_PROGRESS_SIZE} />
        ) : (
          <div style={CIRCULAR_PROGRESS_FAKE_CONTAINER_STYLE} />
        )}
      </Box>
    </Modal>
  );
}

export default GlobalLoading;
