import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { ScheduleWorkingRoutesNodesListInterface } from "../../../../redux/interfaces/expedition.interface";

export const nodesTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Id",
    minW: 125,
  },
  {
    value: "Cod",
    minW: 125,
  },
  {
    value: "Nume",
    minW: 125,
  },
  {
    value: "Adresa",
    minW: 125,
  },
];

export const getNodesTableData: any = (
  data: ScheduleWorkingRoutesNodesListInterface[],
) =>
  data?.map((item: any) => {
    return {
      id: item.id_nod,
      crudData: item,
      data: [
        {
          title: item.id_nod,
        },
        {
          title: item.cod,
        },
        {
          title: item.nodNume,
        },
        {
          title: item.adresa,
        },
      ],
    };
  });
