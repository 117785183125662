import { useState } from "react";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import { useAppDispatch } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import { FleetDocumentsDataInterface } from "../../../../redux/interfaces/fleet.interface";
import { setFleetDocumentData } from "../../../../redux/reducer/fleet.reducer";
import FormButtons from "../../../../components/misc/FormButtons";

function ExistingDocumentsWrapper({
  fleetDocumentsWithoutExpired,
  handleSubmit,
}: {
  fleetDocumentsWithoutExpired: FleetDocumentsDataInterface[];
  handleSubmit: () => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { routeMainParam } = useRoutesHelper();
  const { carId } = routeMainParam as { carId: string };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCardClick = (documentData: FleetDocumentsDataInterface) => {
    dispatch(setFleetDocumentData(documentData));
    handleClose();

    const url = `/fleet/${carId}/documents`;
    window.open(url, "_blank");
  };

  return (
    <>
      <ModalWrapper
        title="Acest document exista deja"
        show={showModal}
        handleClose={handleClose}>
        {fleetDocumentsWithoutExpired.map((item) => {
          return (
            <div
              onClick={() => handleCardClick(item)}
              key={item.id_denumire}
              className="cursor-pointer border-bottom py-2">
              <h2>{item.denumire}</h2>
              <div className="d-flex justify-content-between">
                <p>Creat: {item.doc_ts_start}</p>
                <p className="text-danger">Expira: {item.doc_ts_stop}</p>
              </div>
            </div>
          );
        })}
        <FormButtons
          goBackLabel="Inapoi"
          handleGoBack={handleClose}
          goNextLabel="Creaza"
          handleGoNext={handleSubmit}
        />
      </ModalWrapper>
      <FormButtons
        goBackLabel="Inapoi"
        nextButtonType={"button"}
        goNextLabel={"Documente existente"}
        handleGoBack={() => navigate(-1)}
        handleGoNext={() => setShowModal(true)}
      />
    </>
  );
}

export default ExistingDocumentsWrapper;
