export const transportStatus = [
  { value: 0, label: "Creat" },
  { value: 1, label: "Colet preluat (validat)" },
  {
    value: 2,
    label: "Colet 'posibil' preluat de livrator (coletul este intr-un bin)",
  },
  { value: 3, label: "Colet adus la nod" },
  { value: 4, label: "Colet 'posibil' in bin adus in nod" },
  {
    value: 5,
    label:
      "Colet 'posibil' incarcat in masina intranod (coletul este intr-un bin)",
  },
  {
    value: 6,
    label: "Colet confirmat in masina - nu este intr-un bin (ex: frig)",
  },
  {
    value: 7,
    label:
      "Colet 'posibil' descarcat in nod din masina intranod (bin-ul a fost descarcat intr-un nod)",
  },
  { value: 8, label: "Colet scanat in nod" },
  { value: 9, label: "Colet incarcat pentru livrare" },
  { value: 10, label: "Colet livrat" },
  {
    value: 11,
    label:
      "Colet nelivrat (motivatie in nomenclatorul de motive pentru care nu a livrat la client)",
  },
];
