import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import ContentCard from "../../../components/misc/ContentCard";
import Text from "../../../styleguide/Text";
import SelectableItem from "../../../components/misc/SelectableItem/SelectableItem";

import { fetchFleet } from "../../../redux/api/fleet.api";

import AssignDriverIntranod from "./AssignDriverIntranod";
import AssignDriverRoute from "./AssignDriverRoute";

function AssignDriver() {
  const dispatch = useAppDispatch();

  const [isInternode, setIsInternode] = useState(false);

  useEffect(() => {
    dispatch(
      fetchFleet({
        perPage: 10000,
      }),
    );
  }, [dispatch]);

  return (
    <ContentCard
      CardHeader={() => (
        <div className="d-flex align-items-center justify-content-between w-100">
          <Text variant="h1" className="mb-5">
            Asignare ruta
          </Text>
          <SelectableItem
            name={"internode"}
            title="Intranod"
            className="px-5"
            onClick={() => {
              setIsInternode((prev) => !prev);
            }}
            checked={isInternode}
            type="checkbox"
          />
        </div>
      )}>
      {isInternode ? <AssignDriverIntranod /> : <AssignDriverRoute />}
    </ContentCard>
  );
}

export default AssignDriver;
