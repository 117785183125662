import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { clearCargoNode } from "../../../../redux/reducer/raports.reducer";
import { TableActions } from "../../../../components/tables/tableContext/TableContext";
import { fetchCargoCar } from "../../../../redux/api/raports.api";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import TableWidget from "../../../../components/tables/TableWidget";
import {
  cargoTableHeader,
  getCargoTableData,
} from "./components/tableConfigs/cargoTableConfigs";

function Cargo() {
  const dispatch = useAppDispatch();
  const { routeMainParam } = useRoutesHelper();

  const { idAuto } = routeMainParam as { idAuto: string };

  const {
    raports: {
      cargoNode: {
        data: { data: cargoNode },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    raports: state.raports,
  }));

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      dispatch(
        fetchCargoCar({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          idAuto: idAuto,
        }),
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearCargoNode());
    };
  }, [dispatch]);

  return (
    <TableWidget
      tableTitle="Marfa"
      tableHeaderData={cargoTableHeader}
      tableItemsData={getCargoTableData(cargoNode)}
      tableLoading={isLoading}
      withTablePagination={false}
      handleTableActions={handleTableActions}
      borderedRow
    />
  );
}

export default Cargo;
