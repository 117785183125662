import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { transportStatus } from "../../../../../constants/statuses";
import { SearchInvoiceDataInterface } from "../../../../../redux/interfaces/raports.interface";

const getStatusLabel = (value: any) => {
  const currentStatus = transportStatus.find((item) => {
    return item.value === value;
  });
  return currentStatus?.label;
};

export const searchInvoicesTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Nr. expeditie",
    value: "awb",
    minW: 125,
  },
  {
    label: "Client",
    value: "client",
    minW: 125,
  },
  {
    label: "Punct livrare",
    value: "pctLivrare",
    minW: 125,
  },
  {
    label: "Adresa",
    value: "adresa",
    minW: 125,
  },
  {
    label: "Status",
    value: "status",
    minW: 125,
  },
];

export const getSearchInvoicesTableData = (
  data: SearchInvoiceDataInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_doctrans_detl,
      crudData: item,
      data: [
        {
          title: item.doc_trans,
        },
        {
          title: item.client,
        },
        {
          title: item.pctLivrare,
        },
        {
          title: item.adresa,
        },
        {
          badges: [
            {
              badgeText: getStatusLabel(item.status),
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });
