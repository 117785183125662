import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import { FleetDataInterface } from "../../../../../redux/interfaces/fleet.interface";
import { reportsRoutes } from "../../../../../router/routesConstants";

function CargoCarActionsButtons({
  itemId,
}: {
  itemId: string;
  crudData: FleetDataInterface;
}) {
  const navigate = useNavigate();

  const handleGoToCargo = () => {
    navigate(`${itemId}/${reportsRoutes.cargo}?idAuto=${itemId}`);
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        tooltipTitle="Marfa"
        onClick={handleGoToCargo}>
        <SvgIcon type="DOCUMENTS" fill="white" />
      </CustomButton>
    </div>
  );
}

export default CargoCarActionsButtons;
