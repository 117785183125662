import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import ContentCard from "../../../components/misc/ContentCard";
import useRoutesHelper from "../../../hooks/useRoutesHelper";

import {
  getWorkingRoutesDetails,
  getWorkingRoutesNodes,
  postScheduleRoutes,
} from "../../../redux/api/expedition.api";
import { resetScheduleWorkingRoutesDetails } from "../../../redux/reducer/expedition.reducer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import Text from "../../../styleguide/Text";
import ConfigWorkingDays, {
  AddAllDaysItemScheduleInterface,
} from "../components/ConfigWorkingDays";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import FormButtons from "../../../components/misc/FormButtons";
import { Formik } from "formik";
import TextInput from "../../../components/misc/TextInput";

interface initialValuesInterface {
  name: string;
  code: string;
  selectedNode: DropdownValue | null;
}

const initialValues: initialValuesInterface = {
  name: "",
  code: "",
  selectedNode: null,
};

const formikSchema = Yup.object({
  name: Yup.string().required("Denumirea este obligatorie"),
  code: Yup.string().required("Codul este obligatoriu"),
});

function ExpeditionScheduleRoutesOrganise({ withUpdate = false }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    routeParams: { id },
  } = useRoutesHelper();

  const {
    expedition: {
      scheduleWorkingRoutesDetails: {
        data: { days: workingRouteDays, ruta: routeDetails },
        isLoading: isLoadingRouteDetails,
      },
      workingRoutesNodes: {
        data: workingRoutesNodes,
        isLoading: isLoadingNodes,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [isSaving, setIsSaving] = useState(false);

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  const [selectedDays, setSelectedDays] = useState<
    AddAllDaysItemScheduleInterface[]
  >([]);

  const isInternode = false;

  useEffect(() => {
    if (id) {
      dispatch(getWorkingRoutesDetails({ id: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      getWorkingRoutesNodes({
        page: 1,
        perPage: 10000,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (workingRoutesNodes.data.length) {
      let defaultNode = workingRoutesNodes.data[0];

      if (withUpdate) {
        const defaultNodeId = routeDetails?.id_nod;

        const selectedNode = workingRoutesNodes.data.find(
          (item) => item.id_nod === defaultNodeId,
        );
        if (selectedNode) {
          defaultNode = selectedNode;
        }
      }

      if (defaultNode) {
        setFormikValues((prev) => ({
          ...prev,
          selectedNode: {
            label: defaultNode.nodNume,
            id: defaultNode.id_nod,
          },
        }));
        if (workingRouteDays) {
          setSelectedDays(workingRouteDays);
        }
      }

      if (routeDetails && withUpdate) {
        setFormikValues((prev) => ({
          ...prev,
          code: routeDetails.cod,
          name: routeDetails.denumire,
        }));
      }
    }
  }, [workingRoutesNodes.data, routeDetails, withUpdate, workingRouteDays]);

  useEffect(() => {
    return () => {
      dispatch(resetScheduleWorkingRoutesDetails());
    };
  }, [dispatch]);

  const onlyActiveDays = selectedDays.filter((item) => item.isActive);

  const handleSave = async (value: initialValuesInterface) => {
    if (!value.selectedNode) {
      return;
    }

    setIsSaving(true);

    const resultAction = await dispatch(
      postScheduleRoutes({
        ...value,
        activeWorkingDays: onlyActiveDays,
        internode: isInternode,
        id: id,
        selectedNode: value.selectedNode,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postScheduleRoutes) !==
      endpointStatus.pending
    ) {
      setIsSaving(false);
    }

    if (
      checkEndpointStatus(resultAction, postScheduleRoutes) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const workingNodesDropdownValues = useMemo(
    () =>
      workingRoutesNodes.data?.map((item) => ({
        label: item.nodNume,
        id: item.id_nod,
      })),
    [workingRoutesNodes.data],
  );

  return (
    <ContentCard
      isLoading={isLoadingRouteDetails || isLoadingNodes}
      CardHeader={() => (
        <div className="d-flex align-items-center justify-content-between w-100">
          <Text variant="h1" className="mb-5">
            Organizare ruta locala {formikValues.name}
          </Text>
        </div>
      )}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={formikSchema}
        onSubmit={handleSave}>
        {({
          errors,
          touched,
          values,
          isSubmitting,
          getFieldProps,
          handleSubmit,
          setFieldValue,
        }) => {
          const { selectedNode } = values;

          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <TextInput
                label="Denumire"
                name="name"
                error={errors["name"]}
                touched={touched["name"]}
                inputProps={getFieldProps("name")}
              />
              <TextInput
                label="Cod"
                name="code"
                error={errors["code"]}
                touched={touched["code"]}
                inputProps={getFieldProps("code")}
              />
              <Dropdown
                className="my-4"
                title="Selecteaza nodul"
                data={workingNodesDropdownValues}
                singleValue={selectedNode}
                setSingleValue={(value) => {
                  setFieldValue("selectedNode", value);
                }}
              />

              <ConfigWorkingDays
                itemStyle={{
                  minHeight: 80,
                }}
                alreadyCheckedDays={workingRouteDays}
                withoutTime={isInternode}
                setSelectedDays={setSelectedDays}
              />

              <FormButtons
                goBackLabel="Anuleaza"
                goNextLabel="Salveaza"
                handleGoBack={handleCancel}
                nextButtonDisabled={isSubmitting || onlyActiveDays.length === 0}
                nextButtonLoading={isSaving}
              />
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default ExpeditionScheduleRoutesOrganise;
