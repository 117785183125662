import { TableActions } from "../../../components/tables/tableContext/TableContext";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchDrivers } from "../../../redux/api/assign.api";
import {
  assignedDriversDataTableHeader,
  getAssignedDriversTableData,
} from "./components/tableConfigs/assignedDriversTableConfig";

import AssignActionButton from "./components/TableActionsButtonsComponents/AssignDriverActionButton";
import TableWidget from "../../../components/tables/TableWidget";
import { assignRoutes } from "../../../router/routesConstants";
import FormHeader from "../../../components/misc/FormHeader";

function AssignDrivers() {
  const dispatch = useAppDispatch();

  const {
    assign: {
      assignedDriversData: {
        data: { data: assignedDriversData, total },
        isLoading: isLoadingAssign,
      },
    },
  } = useAppSelector((state) => ({
    assign: state.assign,
  }));

  const handleTableActions = (action: TableActions) => {
    if (action.tableSearch) {
      dispatch(
        fetchDrivers({
          page: 1,
          perPage: 999,
          nume: action.tableSearch.nume,
          prenume: action.tableSearch.prenume,
        }),
      );
    }
  };

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <FormHeader
          title="Alocare rute"
          buttonRoute={`${assignRoutes.assignDriver}`}
          buttonIcon="ADD"
          tooltipTitle="Adauga sofer"
        />
      )}
      tableHeaderData={assignedDriversDataTableHeader}
      tableItemsData={getAssignedDriversTableData(assignedDriversData)}
      totalItems={total}
      borderedRow
      tableLoading={isLoadingAssign}
      ActionButtonsComponent={AssignActionButton}
      handleTableActions={handleTableActions}
      withTablePagination={false}
    />
  );
}

export default AssignDrivers;
