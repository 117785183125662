import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiRequest,
  errorFormatHandler,
  isDevelop,
} from "../../helpers/apiHelper";
import { AddAllDaysItemScheduleInterface } from "../../pages/Expedition/components/ConfigWorkingDays";

import {
  ClientDataInterface,
  ScheduleWorkingRoutesListInterface,
  ScheduleWorkingRoutesNodesListInterface,
} from "../interfaces/expedition.interface";
import { DropdownValue } from "../../components/misc/Dropdown";
import {
  removeUndeliveredAwb,
  setClientStatus,
} from "../reducer/expedition.reducer";

const apiEnv = isDevelop
  ? process.env.REACT_APP_TEST_API_URL
  : process.env.REACT_APP_PUBLIC_API_URL;

export const getScheduleWorkingPoints = createAsyncThunk(
  "/expedition/scheduleWorkingPoints",
  async (
    params: {
      page?: number;
      perPage?: number;
      deliveryPoint: string;
      route?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage, deliveryPoint, route } = params;

    try {
      const { data } = await apiRequest(
        `/nomPuncteLucru?page=${page}&perPage=${perPage}&searchPctLucru=${
          deliveryPoint ?? ""
        }&searchRuta=${route ?? ""}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/scheduleWorkingPoints", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getScheduleWorkingPointsDetails = createAsyncThunk(
  "/expedition/getScheduleWorkingPointsDetails",
  async (
    params: {
      id: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { id: idRuta } = params;
    try {
      const { data } = await apiRequest(`/selectPctLucru/${idRuta}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/expedition/getScheduleWorkingPointsDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postCreateOrUpdateWorkingPoint = createAsyncThunk(
  "/expedition/postCreateOrUpdateWorkingPoint",
  async (
    params: {
      activeWorkingDays: AddAllDaysItemScheduleInterface[];
      id: number;
      selectedWorkingRoute: ScheduleWorkingRoutesListInterface;
      adresa: string;
      denumire: string;
      localitate: string;
      judet: string;
      cod_postal: string;
      pers_contact: string;
      tel_contact: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/createOrUpdatePctLucru",
        "POST",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/postCreateOrUpdateWorkingPoint", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getWorkingRoutes = createAsyncThunk(
  "/expedition/getWorkingRoutes",
  async (
    params: {
      page?: number;
      perPage?: number;
      route?: string;
      id_nod?: string | number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage, route, id_nod } = params;

    try {
      const { data } = await apiRequest(
        `/nomRute?page=${page}&perPage=${perPage}&route=${route ?? ""}&id_nod=${
          id_nod ?? ""
        }`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/getWorkingRoutes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getWorkingRoutesDetails = createAsyncThunk(
  "/expedition/getWorkingRoutesDetails",
  async (
    params: {
      id: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { id: idRuta } = params;
    try {
      const { data } = await apiRequest(`/selectRuta/${idRuta}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/expedition/getWorkingRoutesDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postScheduleRoutes = createAsyncThunk(
  "/expedition/postScheduleRoutes",
  async (
    params: {
      activeWorkingDays: AddAllDaysItemScheduleInterface[];
      internode: boolean;
      id: number;
      selectedNode: DropdownValue;
      name: string;
      code: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/createOrUpdateRuta", "POST", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/postScheduleRoutes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getWorkingRoutesNodes = createAsyncThunk(
  "/expedition/getWorkingRoutesNodes",
  async (
    params: {
      page?: number;
      perPage?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/displayNoduri", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/getWorkingRoutesNodes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postEditNodeDetails = createAsyncThunk(
  "/expedition/postEditNodeDetails",
  async (
    {
      callBack,
      params,
    }: {
      callBack?: () => void;
      params: {
        nodId: number;
        auto: string;
        sofer: string;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/editNod", "POST", params);
      callBack && callBack();
      return data;
    } catch (err: any) {
      console.warn("/expedition/postEditNodeDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getClientsWorkingPoints = createAsyncThunk(
  "/expedition/clientsWorkingPoints",
  async (
    params: {
      page?: number;
      perPage?: number;
      clientId: number | string;
      deliveryPoint: string;
      localitate: string;
      judet: string;
      adresa: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const {
      page,
      perPage,
      deliveryPoint,
      clientId,
      localitate,
      judet,
      adresa,
    } = params;

    try {
      const { data } = await apiRequest(
        `/showClientsWorkingPoints?clientId=${clientId}&page=${page}&perPage=${perPage}&searchLocalitate=${localitate}&searchJudet=${judet}&searchAdresa=${adresa}&searchPctLucru=${deliveryPoint}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/clientsWorkingPoints", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postCreateOrUpdateClient = createAsyncThunk(
  "/expedition/postCreateOrUpdateClient",
  async (params: ClientDataInterface, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(
        "/postCreateOrUpdateClient",
        "POST",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/postCreateOrUpdateClient", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postSetClientStatus = createAsyncThunk(
  "/expedition/postSetClientStatus",
  async (
    params: {
      idClient: number | string;
      status: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/setClientStatus", "POST", params);
      dispatch(setClientStatus(params));
      return data;
    } catch (err: any) {
      console.warn("/expedition/postSetClientStatus", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getClient = createAsyncThunk(
  "/expedition/getClientById",
  async (
    params: { idClient: string | number },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/getClientById", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/getClientById", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getClients = createAsyncThunk(
  "/expedition/getClients",
  async (
    params: {
      page?: number;
      perPage?: number;
      denumireClient?: string;
      localitate?: string;
      judet?: string;
      adresa?: string;
      status?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(`/getClients`, "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/getClients", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUpdateWorkingPoint = createAsyncThunk(
  "/expedition/fetchUpdateWorkingPoint",
  async (
    params: {
      page?: number;
      perPage?: number;
      clientId: number | string;
      deliveryPoint: string;
      localitate: string;
      judet: string;
      adresa: string;
      idPctLucru: number;
      idPctLucruSelectat: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage, clientId } = params;

    try {
      const { data } = await apiRequest(`/updateWorkingPoint`, "POST", params);
      dispatch(
        getClientsWorkingPoints({
          clientId: clientId,
          page: page,
          perPage: perPage,
          deliveryPoint: "",
          adresa: "",
          localitate: "",
          judet: "",
        }),
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/fetchUpdateWorkingPoint", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchAwbHistory = createAsyncThunk(
  "/fetchAwbHistory",
  async (
    params: {
      idClient?: string | number | null;
      page: number;
      perPage: number;
      pctlivrare?: string;
      nodDestinatie?: string;
      status?: number;
      doc_trans?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest("/displayAwb", "POST", {
        ...params,
        id_client: params.idClient,
      });
      return data;
    } catch (err: any) {
      console.log("err", err);
      console.warn("/fetchAwbHistory", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchBinHistory = createAsyncThunk(
  "/fetchBinHistory",
  async (
    params: {
      idClient: string | number;
      page: number;
      perPage: number;
      nodDestinatie?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest("/displayBins", "POST", {
        ...params,
        id_client: params.idClient,
      });
      return data;
    } catch (err: any) {
      console.warn("/fetchBinHistory", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchUndeliveredAwb = createAsyncThunk(
  "/fetchUndeliveredAwb",
  async (
    params: {
      idClient: string | number;
      page?: number;
      perPage?: number;
      pctlivrare?: string;
      nodDestinatie?: string;
      doc_trans?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest("/displayUndeliveredAwbs", "GET", {
        ...params,
        id_client: params.idClient,
      });
      return data;
    } catch (err: any) {
      console.warn("/fetchUndeliveredAwb", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const postAssociateAwbToBin = createAsyncThunk(
  "/expedition/postAssociateAwbToBin",
  async (
    {
      params,
    }: {
      callBack?: () => void;
      params: {
        id_doctrans: number | string;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/associateAwbToBin", "POST", params);

      dispatch(removeUndeliveredAwb(params.id_doctrans));
      return data;
    } catch (err: any) {
      console.warn("/expedition/postAssociateAwbToBin", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchTransferRoute = createAsyncThunk(
  "/expedition/fetchTransferRoute",
  async (
    params: {
      page?: number;
      perPage?: number;
      id_doctrans: number;
      id_ruta: number;
      idClient: string | number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage, idClient } = params;

    try {
      const { data } = await apiRequest(`/transferRoute`, "POST", params);
      dispatch(
        fetchUndeliveredAwb({
          page: page,
          perPage: perPage,
          idClient: idClient,
          pctlivrare: "",
          nodDestinatie: "",
          doc_trans: "",
        }),
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/fetchTransferRoute", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getInternodeRoutes = createAsyncThunk(
  "/expedition/getInternodeRoutes",
  async (
    params: {
      page?: number;
      perPage?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/nomRuteIntranod", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/getInternodeRoutes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getInternodeRouteNodes = createAsyncThunk(
  "/expedition/getInternodeRouteNodes",
  async (
    params: {
      page?: number;
      perPage?: number;
      idRutaIntranod?: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/internodRouteNodes", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/getInternodeRouteNodes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchDeleteInternodRouteNode = createAsyncThunk(
  "/expedition/fetchDeleteInternodRouteNode",
  async (
    params: {
      idIntranodNoduri: string | number;
      page?: number;
      perPage?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/internodeRouteNodeDelete",
        "GET",
        params,
      );

      return data;
    } catch (err: any) {
      console.warn("/expedition/fetchDeleteInternodRouteNode", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchNonExistentNodesForRoute = createAsyncThunk(
  "/expedition/fetchNonExistentNodesForRoute",
  async (
    params: {
      idRutaIntranod?: number | string;
      nodNume?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/getNonexistentNodesOnRoute",
        "GET",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/fetchNonExistentNodesForRoute", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const addInternodeRouteNode = createAsyncThunk(
  "/expedition/addInternodeRouteNode",
  async (
    params: {
      idRutaIntranod?: number | string;
      idNode?: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/addInternodeRouteNode",
        "POST",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/addInternodeRouteNode", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUpdateOrdineNodes = createAsyncThunk(
  "/expedition/fetchUpdateOrdineNodes",
  async (
    params: {
      nodes?: ScheduleWorkingRoutesNodesListInterface[];
      idRutaIntranod?: string | number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/updateOrdineNodes", "POST", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/fetchUpdateOrdineNodes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postCreateBin = createAsyncThunk(
  "/expedition/postCreateBin",
  async (
    params: {
      idNodPreluare?: string | number;
      idNodDestinatie?: string | number;
      cantitate?: string | number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/addBinBarcodeAdmin", "POST", params);
      return data;
    } catch (err: any) {
      console.warn("/expedition/postCreateBin", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchPrintBinA4 = createAsyncThunk(
  "/expedition/fetchPrintBinA4",
  async (
    {
      idBin,
    }: {
      idBin?: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window.open(`${apiEnv}/printSnBinA4/${idBin}`, "_blank")?.focus();
    } catch (err: any) {
      console.warn("/expedition/fetchPrintBinA4", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchPrintBinZebra = createAsyncThunk(
  "/expedition/fetchPrintBinZebra",
  async (
    {
      idBin,
    }: {
      idBin?: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window.open(`${apiEnv}/printSnBinZebra/${idBin}`, "_blank")?.focus();
    } catch (err: any) {
      console.warn("/expedition/fetchPrintBinZebra", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchPrintAwbA4 = createAsyncThunk(
  "/expedition/fetchPrintAwbA4",
  async (
    {
      idDocTrans,
    }: {
      idDocTrans?: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window.open(`${apiEnv}/printAwbA4/${idDocTrans}`, "_blank")?.focus();
    } catch (err: any) {
      console.warn("/expedition/fetchPrintAwbA4", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchPrintAwbZebra = createAsyncThunk(
  "/expedition/fetchPrintAwbZebra",
  async (
    {
      idDocTrans,
    }: {
      idDocTrans?: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window.open(`${apiEnv}/printAwbZebra/${idDocTrans}`, "_blank")?.focus();
    } catch (err: any) {
      console.warn("/expedition/fetchPrintAwbZebra", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getInternodeRouteDetails = createAsyncThunk(
  "/expedition/getInternodeRouteDetails",
  async (
    params: {
      id: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { id: idInternod } = params;
    try {
      const { data } = await apiRequest(
        `/selectedInternode/${idInternod}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/getInternodeRouteDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postScheduleInternodeRoutes = createAsyncThunk(
  "/expedition/postScheduleInternodeRoutes",
  async (
    params: {
      internode: boolean;
      id: number;
      selectedNode: DropdownValue | string | number;
      name: string;
      code: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/createOrUpdateInternode",
        "POST",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/expedition/postScheduleInternodeRoutes", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
