import { useNavigate } from "react-router-dom";

import { crudRoutes } from "../../../../../../router/routesConstants";

import { useAppDispatch } from "../../../../../../redux/hooks";
import { FleetFeatureDocumentsDataInterface } from "../../../../../../redux/interfaces/fleet.interface";
import { setFleetFeatureDocumentData } from "../../../../../../redux/reducer/fleet.reducer";
import { fetchDeleteFeatureDocument } from "../../../../../../redux/api/fleet.api";

import CustomButton from "../../../../../../components/CustomButton";
import SvgIcon from "../../../../../../helpers/SvgIcon";
import useRoutesHelper from "../../../../../../hooks/useRoutesHelper";
import usePermissions from "../../../../../../router/usePermissions";
import userRoles from "../../../../../../constants/userRoles";

function FleetFeatureDocumentsActionButton({
  crudData,
  itemId,
}: {
  itemId: string;
  crudData: FleetFeatureDocumentsDataInterface;
  tableContext: any;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuthorized } = usePermissions();

  const { routeParams } = useRoutesHelper();

  const { nrauto, featureId } = routeParams as {
    nrauto: string;
    featureId: string;
  };

  const {
    CAR_FEATURE_DOCUMENT_DELETE_PERMISSION,
    CAR_FEATURE_DOCUMENT_EDIT_PERMISSION,
  } = {
    CAR_FEATURE_DOCUMENT_EDIT_PERMISSION: isAuthorized(
      userRoles.CAR_FEATURE_DOCUMENT_EDIT,
    ),
    CAR_FEATURE_DOCUMENT_DELETE_PERMISSION: isAuthorized(
      userRoles.CAR_FEATURE_DOCUMENT_DELETE,
    ),
  };

  const handleEdit = () => {
    dispatch(setFleetFeatureDocumentData(crudData));
    navigate(`${itemId}/${crudRoutes.edit}?nrauto=${nrauto}`);
  };

  const handleDelete = () => {
    dispatch(fetchDeleteFeatureDocument({ id: itemId, featureId: featureId }));
  };

  return (
    <div>
      {CAR_FEATURE_DOCUMENT_EDIT_PERMISSION && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Editeaza detalii document"
          onClick={handleEdit}>
          <SvgIcon type="EDIT" />
        </CustomButton>
      )}
      {CAR_FEATURE_DOCUMENT_DELETE_PERMISSION && (
        <CustomButton
          variant="contained"
          color="error"
          className="mt-1 me-1"
          tooltipTitle="Stergere document"
          withConfirmationModal={{
            modalTitle: `Doriti sa stergeti acest document?`,
            modalLeftButtonColor: "inherit",
            modalRightButtonOnClick: handleDelete,
            modalRightButtonText: "Sterge",
            modalLeftButtonText: "Anuleaza",
          }}>
          <SvgIcon type="DELETE" />
        </CustomButton>
      )}
    </div>
  );
}

export default FleetFeatureDocumentsActionButton;
