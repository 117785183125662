import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";
import {
  FleetDataInterface,
  FleetDocumentsDataInterface,
  FleetFeatureDocumentsDataInterface,
  FleetFeaturesDataInterface,
} from "../interfaces/fleet.interface";
import { PartialOrNull } from "../interfaces/general.interface";

export const fetchFleet = createAsyncThunk(
  "/fetchFleet",
  async (
    params: {
      page?: number;
      perPage: number;
      nrauto?: string;
      marca?: string;
      model?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest(`/displayFlota`, "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/fetchFleet", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchFleetFeatures = createAsyncThunk(
  "/fetchFleetFeatures",
  async (params: { id: number | string }, { rejectWithValue }) => {
    const { id } = params;
    try {
      const { data } = await apiRequest(`/showEchipare/${id}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/fetchFleetFeatures", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchFleetFeatureDocuments = createAsyncThunk(
  "/fetchFleetFeatureDocuments",
  async (params: { id: number | string }, { rejectWithValue }) => {
    const { id } = params;
    try {
      const { data } = await apiRequest(`/showDocsCalitate/${id}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/fetchFleetFeatureDocuments", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchShowDocs = createAsyncThunk(
  "/fetchShowDocs",
  async (
    params: { id: number | string; tip_doc?: number | string },
    { rejectWithValue },
  ) => {
    const { id } = params;
    try {
      const { data } = await apiRequest(`/showDocs/${id}`, "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/fetchShowDocs", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchAddOrUpdateCar = createAsyncThunk(
  "/fetchAddOrUpdateCar",
  async (params: PartialOrNull<FleetDataInterface>, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest(`/flotaStoreOrUpdate`, "POST", params);
      return data;
    } catch (err: any) {
      console.warn("/fetchAddOrUpdateCar", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchAddOrUpdateFeature = createAsyncThunk(
  "/fetchAddOrUpdateFeature",
  async (
    params: PartialOrNull<FleetFeaturesDataInterface>,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest(
        `/calitateStoreOrUpdate`,
        "POST",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/fetchAddOrUpdateFeature", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchAddOrUpdateFeatureDocument = createAsyncThunk(
  "/fetchAddOrUpdateFeatureDocument",
  async (
    params: PartialOrNull<FleetFeatureDocumentsDataInterface>,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest(
        `/calitateDocStoreOrUpdate`,
        "POST",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/fetchAddOrUpdateFeatureDocument", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchAddOrUpdateDocument = createAsyncThunk(
  "/fetchAddOrUpdateDocument",
  async (
    params: PartialOrNull<FleetDocumentsDataInterface>,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest(`/docStoreOrUpdate`, "POST", params);
      return data;
    } catch (err: any) {
      console.warn("/fetchAddOrUpdateDocument", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchDeleteCar = createAsyncThunk(
  "/fetchDeleteCar",
  async (
    params: { id: number | string; page: number; perPage: number },
    { rejectWithValue, dispatch },
  ) => {
    const { id, page, perPage } = params;
    try {
      const { data } = await apiRequest(`/flotaDelete/${id}`, "GET");
      dispatch(fetchFleet({ page, perPage }));
      return data;
    } catch (err: any) {
      console.warn("/fetchDeleteCar", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchDeleteFeature = createAsyncThunk(
  "/fetchDeleteFeature",
  async (
    params: { id: number | string; carId: number | string },
    { rejectWithValue, dispatch },
  ) => {
    const { id, carId } = params;
    try {
      const { data } = await apiRequest(`/calitateDelete/${id}`, "GET");
      dispatch(fetchFleetFeatures({ id: carId }));
      return data;
    } catch (err: any) {
      console.warn("/fetchDeleteFeature", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchDeleteFeatureDocument = createAsyncThunk(
  "/fetchDeleteFeatureDocument",
  async (
    params: { id: number | string; featureId: number | string },
    { rejectWithValue, dispatch },
  ) => {
    const { id, featureId } = params;
    try {
      const { data } = await apiRequest(`/calitateDocDelete/${id}`, "GET");
      dispatch(fetchFleetFeatureDocuments({ id: featureId }));
      return data;
    } catch (err: any) {
      console.warn("/fetchDeleteFeatureDocument", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchDeleteDocument = createAsyncThunk(
  "/fetchDeleteDocument",
  async (
    params: { id: number | string; carId: number | string },
    { rejectWithValue, dispatch },
  ) => {
    const { id, carId } = params;
    try {
      const { data } = await apiRequest(`/docDelete/${id}`, "GET");
      dispatch(fetchShowDocs({ id: carId }));
      return data;
    } catch (err: any) {
      console.warn("/fetchDeleteDocument", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchFleetTypes = createAsyncThunk(
  "/fetchFleetTypes",
  async (
    params: {
      table:
        | "nom_flota_calitate"
        | "nom_flota_doc"
        | "nom_flota_caldoc"
        | "nom_flota_auto"
        | "tip_ambalaj";
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest(`/showDenumiri`, "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/fetchFleetTypes", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchShowAllDocTypes = createAsyncThunk(
  "/fetchShowAllDocTypes",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest("/showAllDocTypes", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/fetchShowAllDocTypes", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchAllFleetDocs = createAsyncThunk(
  "/fetchAllFleetDocs",
  async (
    params: {
      idAuto?: number | string | null;
      page: number;
      per_page: number;
      valability: string;
      documentType: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest("/showAllFleetDocs", "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/fetchAllFleetDocs", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);
