import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import TableWidget from "../../../components/tables/TableWidget";
import {
  cargoCarTableHeader,
  getCargoCarTableData,
} from "./components/tableConfigs/cargoCarTableConfig";
import CargoCarActionsButtons from "./components/ActionsButtons/CargoCarActionsButtons";
import { fetchActiveCargoCars } from "../../../redux/api/raports.api";

function CargoCar() {
  const dispatch = useAppDispatch();

  const {
    raports: {
      activeCargoCars: {
        data: { data: activeCargoCars, total },
        isLoading: isLoading,
      },
    },
  } = useAppSelector((state) => ({
    raports: state.raports,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    ) {
      dispatch(
        fetchActiveCargoCars({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          marca: action.tableSearch?.marca ?? "",
          nrauto: action.tableSearch?.nrauto ?? "",
          model: action.tableSearch?.model ?? "",
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle="Colete aflate in autovehicul"
      tableHeaderData={cargoCarTableHeader}
      tableItemsData={getCargoCarTableData(activeCargoCars)}
      totalItems={total}
      borderedRow
      tableLoading={isLoading}
      ActionButtonsComponent={CargoCarActionsButtons}
      handleTableActions={handleTableActions}
    />
  );
}

export default CargoCar;
