import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import {
  getCurrentService,
  postCreateOrUpdateService,
} from "../../../../redux/api/admin.api";
import { clearCurrentService } from "../../../../redux/reducer/admin.reducer";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";
import ContentCard from "../../../../components/misc/ContentCard";
import { Formik } from "formik";
import TextInput from "../../../../components/misc/TextInput";
import FormButtons from "../../../../components/misc/FormButtons";

interface initialValuesInterface {
  denumire: string;
  pret: string | number;
}

const initialValues = {
  denumire: "",
  pret: "",
};

const validationSchema = Yup.object({
  denumire: Yup.string().required("Denumirea este obligatorie"),
  pret: Yup.string().required("Pretul este obligatoriu"),
});

function CreateOrUpdateService() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { routeMainParam } = useRoutesHelper();

  const { idServiciu } = routeMainParam as { idServiciu: string };

  const {
    admin: {
      currentService: { data: serviceData, isLoading },
    },
  } = useAppSelector((state) => ({
    admin: state.admin,
  }));

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (idServiciu) {
      dispatch(
        getCurrentService({
          idServiciu: idServiciu,
        }),
      );
    }
  }, [dispatch, idServiciu]);

  useEffect(() => {
    if (idServiciu && serviceData) {
      setFormikValues({
        denumire: serviceData.denumire,
        pret: serviceData.pret,
      });
    }
  }, [idServiciu, serviceData]);

  useEffect(() => {
    return () => {
      dispatch(clearCurrentService());
    };
  }, [dispatch]);

  const handleSaveServiceDetails = async (value: initialValuesInterface) => {
    setIsSubmitting(true);

    const resultAction = await dispatch(
      postCreateOrUpdateService({
        ...value,
        idServiciu: idServiciu,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postCreateOrUpdateService) !==
      endpointStatus.pending
    ) {
      setIsSubmitting(false);
    }

    if (
      checkEndpointStatus(resultAction, postCreateOrUpdateService) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const title =
    (idServiciu
      ? "Editeaza informatii serviciu"
      : "Adauga informatii serviciu") + ` ${formikValues.denumire || ""}`;

  return (
    <ContentCard
      cardTitle={title}
      cardHeaderClassName="text-center"
      isLoading={isLoading}
      CardHeader={() => <></>}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={validationSchema}
        onSubmit={handleSaveServiceDetails}>
        {({ isValid, errors, touched, getFieldProps, handleSubmit }) => {
          return (
            <form className="form w-100" onSubmit={handleSubmit} noValidate>
              <TextInput
                type="text"
                label="Denumire"
                name="denumire"
                error={errors["denumire"]}
                touched={touched["denumire"]}
                inputProps={getFieldProps("denumire")}
                withVerticalSpacer
              />
              <TextInput
                type="number"
                label="Pret"
                name="pret"
                error={errors["pret"]}
                touched={touched["pret"]}
                inputProps={getFieldProps("pret")}
                withVerticalSpacer
              />

              <FormButtons
                goBackLabel="Anuleaza"
                goNextLabel="Salveaza"
                handleGoBack={handleCancel}
                nextButtonDisabled={isLoading || isSubmitting || !isValid}
                nextButtonLoading={isSubmitting}
              />
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default CreateOrUpdateService;
