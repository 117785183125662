import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCargoNode } from "../../../redux/api/raports.api";
import TableWidget from "../../../components/tables/TableWidget";
import {
  cargoNodeTableHeader,
  getCargoNodeTableData,
} from "./components/tableConfigs/cargoNodeTableConfig";
import ContentCard from "../../../components/misc/ContentCard";
import { getWorkingRoutesNodes } from "../../../redux/api/expedition.api";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";
import { clearCargoNode } from "../../../redux/reducer/raports.reducer";

function CargoNode() {
  const dispatch = useAppDispatch();

  const {
    raports: {
      cargoNode: {
        data: { data: cargoNode },
        isLoading,
      },
    },
    expedition: {
      workingRoutesNodes: {
        data: { data: workingRoutesNodes },
      },
    },
  } = useAppSelector((state) => ({
    raports: state.raports,
    expedition: state.expedition,
  }));

  useEffect(() => {
    dispatch(
      getWorkingRoutesNodes({
        page: 1,
        perPage: 20,
      }),
    );
  }, [dispatch]);

  const [selectedNode, setSelectedNode] = useState<DropdownValue | null>(null);

  const nodesDropdownData = workingRoutesNodes.map((item) => ({
    id: item.id_nod,
    label: item.nodNume,
  }));

  const handleNodeSelected = (value: any) => {
    setSelectedNode(value);
  };

  useEffect(() => {
    if (selectedNode) {
      dispatch(
        fetchCargoNode({
          page: 1,
          perPage: 999,
          idNod: selectedNode?.id,
        }),
      );
    }
  }, [dispatch, selectedNode]);

  useEffect(() => {
    return () => {
      dispatch(clearCargoNode());
      setSelectedNode(null);
    };
  }, [dispatch]);

  return (
    <ContentCard cardTitle="Marfa nod">
      <Dropdown
        className="my-4"
        data={nodesDropdownData}
        placeholder="Selecteaza nodul"
        singleValue={selectedNode}
        setSingleValue={handleNodeSelected}
      />

      <TableWidget
        tableHeaderData={cargoNodeTableHeader}
        tableItemsData={getCargoNodeTableData(cargoNode)}
        tableLoading={isLoading}
        withTablePagination={false}
        borderedRow
      />
    </ContentCard>
  );
}

export default CargoNode;
