import ContentCard from "../../../components/misc/ContentCard";
import CustomButton from "../../../components/CustomButton";
import InternodeRoutes from "../InternodeRoutes";
import { useNavigate } from "react-router-dom";
import { expeditionRoutes } from "../../../router/routesConstants";

function RoutesOrganizationInternode() {
  const navigate = useNavigate();

  const handleButtonPress = () => {
    navigate(`/${expeditionRoutes.routesOrganizationLocal}`);
  };

  return (
    <>
      <ContentCard cardBodyClassName="d-flex">
        <CustomButton
          variant={"outlined"}
          style={{ borderEndEndRadius: 0, borderStartEndRadius: 0 }}
          fullWidth
          onClick={handleButtonPress}>
          Rute locale
        </CustomButton>
        <CustomButton
          variant={"contained"}
          style={{ borderEndStartRadius: 0, borderStartStartRadius: 0 }}
          fullWidth>
          Rute internod
        </CustomButton>
      </ContentCard>

      <InternodeRoutes />
    </>
  );
}

export default RoutesOrganizationInternode;
