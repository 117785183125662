import { useEffect, useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  fetchAddOrUpdateFeatureDocument,
  fetchFleetTypes,
} from "../../../../redux/api/fleet.api";
import {
  clearFleetFeatureDocumentData,
  clearFleetTypeData,
} from "../../../../redux/reducer/fleet.reducer";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";

import useRoutesHelper from "../../../../hooks/useRoutesHelper";

import ContentCard from "../../../../components/misc/ContentCard";
import TextInput from "../../../../components/misc/TextInput";
import FormButtons from "../../../../components/misc/FormButtons";

import Dropdown from "../../../../components/misc/Dropdown";
import DatePicker from "../../../../components/misc/DatePicker";
import { getCurrentDate } from "../../../../utils/dateAndTime";

interface initialValuesInterface {
  tip: number | string | null;
  caldoc_obs: string;
  doc_ts_start: string;
  doc_ts_stop: string;
}

const initialValues: initialValuesInterface = {
  tip: null,
  caldoc_obs: "",
  doc_ts_start: "",
  doc_ts_stop: "",
};

const formikSchema = Yup.object({
  doc_ts_start: Yup.date()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Mandatory field message"),

  doc_ts_stop: Yup.date()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Mandatory field message")
    .min(Yup.ref("doc_ts_start"), "End date must be after start date"),
});

function FleetFeatureDocumentsEdit({ withUpdate }: { withUpdate?: boolean }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { routeMainParam, routeParams } = useRoutesHelper();
  const { carId, featureId, documentId } = routeMainParam as {
    carId: string;
    featureId: string;
    documentId: string;
  };

  const { nrauto } = routeParams as { nrauto: string };

  const {
    fleet: {
      fleetFeatureDocumentData,
      fleetTypeData: { data: fleetTypesData, isLoading: fleetTypesLoading },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchFleetTypes({ table: "nom_flota_caldoc" }));

    return () => {
      dispatch(clearFleetTypeData());
      dispatch(clearFleetFeatureDocumentData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (fleetTypesData.length) {
      // we set an initial dropdown data
      const initialTypeId = fleetTypesData[0].id;
      setFormikValues((prev) => {
        return {
          ...prev,
          tip: initialTypeId,
          doc_ts_start: getCurrentDate(),
          doc_ts_stop: getCurrentDate(),
        };
      });

      if (fleetFeatureDocumentData && withUpdate) {
        setFormikValues((prev) => ({
          ...prev,
          tip: fleetFeatureDocumentData.tip,
          valoare: fleetFeatureDocumentData.valoare,
          caldoc_obs: fleetFeatureDocumentData.caldoc_obs,
          doc_ts_start: fleetFeatureDocumentData.doc_ts_start,
          doc_ts_stop: fleetFeatureDocumentData.doc_ts_stop,
        }));
      }
    }
  }, [fleetTypesData, fleetFeatureDocumentData, withUpdate]);

  const handleSaveProductDetails = async (value: initialValuesInterface) => {
    setIsLoading(true);

    const resultAction = await dispatch(
      fetchAddOrUpdateFeatureDocument({
        ...fleetFeatureDocumentData,
        ...value,
        id_auto: carId,
        id_calitate: featureId,
        id_caldoc: documentId,
      }),
    );

    if (
      checkEndpointStatus(resultAction, fetchAddOrUpdateFeatureDocument) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, fetchAddOrUpdateFeatureDocument) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  if (!fleetFeatureDocumentData && withUpdate) {
    return (
      <ContentCard>
        <h1>Document inexistent</h1>
      </ContentCard>
    );
  }

  const title =
    (withUpdate
      ? "Editeaza document care asigura calitatea trasnportului pt auto"
      : "Adauga document care asigura calitatea trasnportului pt auto") +
    ` ${nrauto || ""}`;

  return (
    <ContentCard
      isLoading={fleetTypesLoading}
      cardTitle={title}
      cardHeaderClassName="text-center"
      isLoadingClassName="d-flex flex-column justify-content-center align-items-center vh-75"
      CardHeader={() => <></>}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={formikSchema}
        onSubmit={handleSaveProductDetails}>
        {({
          isValid,
          errors,
          touched,
          values,
          getFieldProps,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          const selectedType = fleetTypesData?.find(
            (item) => item.id === values.tip,
          );
          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <Dropdown
                className="my-4"
                title="Selecteaza tipul de document"
                data={fleetTypesData}
                singleValue={selectedType}
                setSingleValue={(value) => {
                  setFieldValue("tip", value?.id);
                }}
              />

              <div className="d-md-flex d-block">
                <DatePicker
                  label="Data incepere valabilitate"
                  value={values.doc_ts_start}
                  handleChangeDate={(newDate) => {
                    setFieldValue("doc_ts_start", newDate);
                  }}
                  error={errors["doc_ts_start"]}
                />

                <DatePicker
                  label="Data sfarsit valabilitate"
                  value={values.doc_ts_stop}
                  minDate={new Date(values.doc_ts_start)}
                  handleChangeDate={(newDate) => {
                    setFieldValue("doc_ts_stop", newDate);
                  }}
                  error={errors["doc_ts_stop"]}
                  withVerticalSpacer
                />
              </div>
              <TextInput
                type="text"
                label="Observatii"
                name="caldoc_obs"
                error={errors["caldoc_obs"]}
                touched={touched["caldoc_obs"]}
                inputProps={getFieldProps("caldoc_obs")}
              />

              <FormButtons
                goBackLabel="Inapoi"
                goNextLabel={isSubmitting ? "Se incarca..." : "Salveaza"}
                nextButtonDisabled={!isValid || isSubmitting}
                nextButtonLoading={isSubmitting || isLoading}
                handleGoBack={handleGoBack}
              />
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default FleetFeatureDocumentsEdit;
