import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";

export const fetchDrivers = createAsyncThunk(
  "/fetchDrivers",
  async (
    params: {
      perPage: number;
      page?: number;
      nume?: string;
      prenume?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest(`/displaySoferi`, "POST", {
        // user role 10 is the role for drivers
        user_role: 10,
        ...params,
      });
      return data;
    } catch (err: any) {
      console.warn("/fetchDrivers", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchUniqueDrivers = createAsyncThunk(
  "/fetchUniqueDrivers",
  async (
    params: {
      perPage: number;
      page?: number;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest(`/displayUniqueDrivers`, "POST", {
        ...params,
      });
      return data;
    } catch (err: any) {
      console.warn("/fetchDrivers", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchRoutesNomenclatures = createAsyncThunk(
  "/fetchRoutesNomenclatures",
  async (params: { perPage: number }, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest(`/nomRute`, "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/fetchRoutesNomenclatures", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchIntranodesNomenclatures = createAsyncThunk(
  "/fetchIntranodesNomenclatures",
  async (params: { perPage: number }, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest(`/nomRuteIntranod`, "GET", params);
      return data;
    } catch (err: any) {
      console.warn("/fetchIntranodesNomenclatures", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchAssignDriverToRoute = createAsyncThunk(
  "/fetchAssignDriverToRoute",
  async (
    params: {
      id_user?: string | number | null;
      id_ruta?: string | number;
      id_ruta_intranod?: string | number;
      sofer_data_start?: string;
      sofer_data_stop?: string;
      id_auto?: string | number;
      id_nod_start?: string | number;
      id_nod_stop?: string | number;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest(`/alocaSofer`, "POST", params);
      return data;
    } catch (err: any) {
      console.warn("/fetchAssignDriverToRoute", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchAssignDriverToNewRoute = createAsyncThunk(
  "/fetchAssignDriverToNewRoute",
  async (
    params: {
      id_user?: string | number | null;
      id_ruta?: string | number;
      id_ruta_intranod?: string | number;
      sofer_data_start?: string;
      sofer_data_stop?: string;
      id_auto?: string | number;
      id_nod_start?: string | number;
      id_nod_stop?: string | number;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await apiRequest(
        `/assignDriverToNewRoute`,
        "POST",
        params,
      );
      return data;
    } catch (err: any) {
      console.warn("/fetchAssignDriverToNewRoute", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchDeactivateOrActivateRoute = createAsyncThunk(
  "/fetchDeactivateOrActivateRoute",
  async (
    params: {
      id_sofer_rute: string | number;
      activ: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        `/deactivateOrActivateRoute`,
        "POST",
        params,
      );
      dispatch(fetchDrivers({ perPage: 999, page: 1 }));
      return data;
    } catch (err: any) {
      console.warn("/fetchDeactivateOrActivateRoute", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);
