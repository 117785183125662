import React from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import CustomButton from "../../../../components/CustomButton";
import { AwbHistoryDataInterface } from "../../../../redux/interfaces/expedition.interface";
import {
  fetchPrintAwbA4,
  fetchPrintAwbZebra,
} from "../../../../redux/api/expedition.api";
import SvgIcon from "../../../../helpers/SvgIcon";

function AwbHistoryActionButtons({
  itemId,
}: {
  itemId: string;
  crudData: AwbHistoryDataInterface;
}) {
  const dispatch = useAppDispatch();

  const handlePrintAwbA4 = () => {
    if (itemId) {
      dispatch(
        fetchPrintAwbA4({
          idDocTrans: itemId,
        }),
      );
    }
  };

  const handlePrintAwbZebra = () => {
    if (itemId) {
      dispatch(
        fetchPrintAwbZebra({
          idDocTrans: itemId,
        }),
      );
    }
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        tooltipTitle="Print A4"
        onClick={handlePrintAwbA4}>
        <SvgIcon type="PRINT_A4" fill="white" />
      </CustomButton>

      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        tooltipTitle="Print Zebra"
        onClick={handlePrintAwbZebra}>
        <SvgIcon type="PRINT_ZEBRA" />
      </CustomButton>
    </div>
  );
}

export default AwbHistoryActionButtons;
