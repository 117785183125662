import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

import TextInput from "../../../../components/misc/TextInput";
import ContentCard from "../../../../components/misc/ContentCard";

import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";
import {
  getClient,
  postCreateOrUpdateClient,
} from "../../../../redux/api/expedition.api";
import { clearClient } from "../../../../redux/reducer/expedition.reducer";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import FormButtons from "../../../../components/misc/FormButtons";

import CountriesAndLocalitiesDropdowns from "../../../../components/CountriesAndLocalitiesDropdowns";

interface initialValuesInterface {
  emailClient: string;
  password: string;
  confirmPassword: string;
  denumireClient: string;
  regcom: string;
  cui: string;
  adresa: string;
  localitate: string;
  judet: string;
  codPostal: number | null;
  cont: string;
  banca: string;
  persoanaContact: string;
  telefonClient: number | null;
}

const initialValues = {
  emailClient: "",
  password: "",
  confirmPassword: "",
  denumireClient: "",
  regcom: "",
  cui: "",
  adresa: "",
  localitate: "",
  judet: "",
  codPostal: null,
  cont: "",
  banca: "",
  persoanaContact: "",
  telefonClient: null,
};

const validationSchema = Yup.object({
  emailClient: Yup.string()
    .email("Email invalid")
    .required("Email obligatoriu"),
  password: Yup.string()
    .nullable()
    .min(6, "Parola trebuie să aibă minim 6 caractere"),
  confirmPassword: Yup.string()
    .nullable()
    .oneOf([Yup.ref("password"), null], "Parolele trebuie să fie identice"),
  denumireClient: Yup.string().required("Numele clientului este obligatoriu"),
  regcom: Yup.string()
    .nullable()
    .max(20, "Regcom-ul trebuie să aibă maxim 20 caractere"),
  cui: Yup.string()
    .nullable()
    .max(20, "CUI-ul trebuie să aibă maxim 20 caractere"),
  adresa: Yup.string().nullable(),
  localitate: Yup.string()
    .nullable()
    .notOneOf(["-"], "Trebuie sa alegi o localitate")
    .required("Trebuie sa alegi o localitate"),
  judet: Yup.string()
    .nullable()
    .notOneOf(["-"], "Trebuie sa alegi un judet")
    .required("Trebuie sa alegi un judet"),
  codPostal: Yup.string()
    .nullable()
    .max(10, "Codul postal trebuie să aibă maxim 10 caractere"),
  cont: Yup.string()
    .nullable()
    .max(30, "Contul trebuie să aibă maxim 30 caractere"),
  banca: Yup.string().nullable(),
  persoanaContact: Yup.string().nullable(),
  telefonClient: Yup.string()
    .nullable()
    .max(15, "Numărul de telefon trebuie să aibă maxim 15 caractere"),
});

function CreateOrUpdateClient({ withUpdate }: { withUpdate?: boolean }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { routeMainParam } = useRoutesHelper();

  const { clientId } = routeMainParam as { clientId: string };

  const {
    expedition: {
      client: { data: clientData, isLoading },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (clientId) {
      dispatch(getClient({ idClient: clientId }));
    }

    return () => {
      dispatch(clearClient());
    };
  }, [dispatch, clientId]);

  useEffect(() => {
    if (clientId && clientData) {
      setFormikValues({
        emailClient: clientData.emailClient,
        password: clientData.password || "",
        confirmPassword: clientData.password || "",
        denumireClient: clientData.denumireClient,
        regcom: clientData.regcom || "",
        cui: clientData.cui || "",
        adresa: clientData.adresa || "",
        localitate: clientData.localitate || "",
        judet: clientData.judet || "",
        codPostal: clientData.codPostal || null,
        cont: clientData.cont || "",
        banca: clientData.banca || "",
        persoanaContact: clientData.persoanaContact || "",
        telefonClient: clientData.telefonClient || null,
      });
    }
  }, [withUpdate, clientId, clientData]);

  useEffect(() => {
    return () => {
      dispatch(clearClient());
    };
  }, [dispatch]);

  const handleSaveClientDetails = async (value: initialValuesInterface) => {
    setIsSubmitting(true);

    const resultAction = await dispatch(
      postCreateOrUpdateClient({
        ...value,
        idClient: clientId,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postCreateOrUpdateClient) !==
      endpointStatus.pending
    ) {
      setIsSubmitting(false);
    }

    if (
      checkEndpointStatus(resultAction, postCreateOrUpdateClient) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const title =
    (clientId ? "Editeaza informatii client" : "Adauga informatii client") +
    ` ${clientData?.denumireClient || ""}`;

  return (
    <ContentCard
      cardTitle={title}
      cardHeaderClassName="text-center"
      isLoading={isLoading}
      CardHeader={() => <></>}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={validationSchema}
        onSubmit={handleSaveClientDetails}>
        {({
          isValid,
          errors,
          touched,
          getFieldProps,
          handleSubmit,
          setFieldValue,
          values,
        }) => {
          return (
            <form className="form w-100" onSubmit={handleSubmit} noValidate>
              <TextInput
                type="text"
                label="Email"
                name="emailClient"
                error={errors["emailClient"]}
                touched={touched["emailClient"]}
                inputProps={getFieldProps("emailClient")}
                withVerticalSpacer
              />
              <TextInput
                type="password"
                label="Parola"
                name="password"
                error={errors["password"]}
                touched={touched["password"]}
                inputProps={getFieldProps("password")}
                withVerticalSpacer
              />
              <TextInput
                type="password"
                label="Confirmă Parola"
                name="confirmPassword"
                error={errors["confirmPassword"]}
                touched={touched["confirmPassword"]}
                inputProps={getFieldProps("confirmPassword")}
                withVerticalSpacer
              />
              <TextInput
                type="text"
                label="Denumire Client"
                name="denumireClient"
                error={errors["denumireClient"]}
                touched={touched["denumireClient"]}
                inputProps={getFieldProps("denumireClient")}
                withVerticalSpacer
              />
              <TextInput
                type="text"
                label="Regcom"
                name="regcom"
                error={errors["regcom"]}
                touched={touched["regcom"]}
                inputProps={getFieldProps("regcom")}
                withVerticalSpacer
              />
              <TextInput
                type="text"
                label="CUI"
                name="cui"
                error={errors["cui"]}
                touched={touched["cui"]}
                inputProps={getFieldProps("cui")}
                withVerticalSpacer
              />
              <TextInput
                type="text"
                label="Adresă"
                name="adresa"
                error={errors["adresa"]}
                touched={touched["adresa"]}
                inputProps={getFieldProps("adresa")}
                withVerticalSpacer
              />
              <CountriesAndLocalitiesDropdowns
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
              <TextInput
                type="text"
                label="Cod Postal"
                name="codPostal"
                error={errors["codPostal"]}
                touched={touched["codPostal"]}
                inputProps={getFieldProps("codPostal")}
                withVerticalSpacer
              />
              <TextInput
                type="text"
                label="Cont"
                name="cont"
                error={errors["cont"]}
                touched={touched["cont"]}
                inputProps={getFieldProps("cont")}
                withVerticalSpacer
              />
              <TextInput
                type="text"
                label="Banca"
                name="banca"
                error={errors["banca"]}
                touched={touched["banca"]}
                inputProps={getFieldProps("banca")}
                withVerticalSpacer
              />
              <TextInput
                type="text"
                label="Persoană Contact"
                name="persoanaContact"
                error={errors["persoanaContact"]}
                touched={touched["persoanaContact"]}
                inputProps={getFieldProps("persoanaContact")}
                withVerticalSpacer
              />
              <TextInput
                type="text"
                label="Telefon Client"
                name="telefonClient"
                error={errors["telefonClient"]}
                touched={touched["telefonClient"]}
                inputProps={getFieldProps("telefonClient")}
                withVerticalSpacer
              />

              <FormButtons
                goBackLabel="Anuleaza"
                goNextLabel="Salveaza"
                handleGoBack={handleCancel}
                nextButtonDisabled={isLoading || isSubmitting || !isValid}
                nextButtonLoading={isSubmitting}
              />
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default CreateOrUpdateClient;
