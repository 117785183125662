import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import {
  getWorkingRoutesNodes,
  postCreateBin,
} from "../../../redux/api/expedition.api";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";
import ContentCard from "../../../components/misc/ContentCard";
import TextInput from "../../../components/misc/TextInput";
import FormButtons from "../../../components/misc/FormButtons";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";

function CreateBin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    expedition: {
      workingRoutesNodes: {
        data: { data: workingRoutesNodes },
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [binQuantity, setBinQuantity] = useState<string | number | null>(null);

  const [selectedReceivingNode, setSelectedReceivingNode] =
    useState<DropdownValue | null>(null);
  const [selectedDestinationNode, setSelectedDestinationNode] =
    useState<DropdownValue | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      getWorkingRoutesNodes({
        page: 1,
        perPage: 50,
      }),
    );
  }, [dispatch]);

  const handleReceivingNodeSelected = (value: any) => {
    setSelectedReceivingNode(value);
  };
  const handleDestinationNodeSelected = (value: any) => {
    setSelectedDestinationNode(value);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleAddBin = async () => {
    if (
      !selectedReceivingNode?.id ||
      !binQuantity ||
      !selectedDestinationNode?.id
    ) {
      return;
    }

    setIsLoading(true);

    const resultAction = await dispatch(
      postCreateBin({
        idNodPreluare: selectedReceivingNode.id,
        idNodDestinatie: selectedDestinationNode.id,
        cantitate: binQuantity,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postCreateBin) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postCreateBin) ===
      endpointStatus.fulfilled
    ) {
      setBinQuantity(null);
      setSelectedDestinationNode(null);
      setSelectedReceivingNode(null);
      navigate(-1);
    }
  };

  const nodesDropdownData = workingRoutesNodes.map((item) => ({
    id: item.id_nod,
    label: item.nodNume,
  }));

  const disableSubmit =
    !selectedReceivingNode ||
    !binQuantity ||
    !selectedDestinationNode ||
    selectedReceivingNode.id === selectedDestinationNode.id;

  return (
    <ContentCard cardTitle="Formeaza BIN" isLoading={isLoading}>
      <div>
        <Dropdown
          className="my-4 mt-10"
          title="Nod preluare"
          data={nodesDropdownData}
          singleValue={{
            id: selectedReceivingNode?.id || "",
            label: selectedReceivingNode?.label || "",
          }}
          setSingleValue={handleReceivingNodeSelected}
        />
        <Dropdown
          className="my-4"
          title="Nod destinate"
          data={nodesDropdownData}
          singleValue={{
            id: selectedDestinationNode?.id || "",
            label: selectedDestinationNode?.label || "",
          }}
          setSingleValue={handleDestinationNodeSelected}
        />

        <TextInput
          label={"Cantitate"}
          name={"cantitate"}
          type="number"
          value={binQuantity}
          onChange={(value) => setBinQuantity(value)}
          withVerticalSpacer
        />
      </div>
      <FormButtons
        goBackLabel="Inapoi"
        goNextLabel="Salveaza"
        nextButtonDisabled={disableSubmit}
        handleGoBack={handleGoBack}
        handleGoNext={handleAddBin}
      />
    </ContentCard>
  );
}

export default CreateBin;
