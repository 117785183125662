import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { ActiveTransportsDataInterface } from "../../../../../redux/interfaces/raports.interface";
import { getDateAndTime } from "../../../../../utils/dateAndTime";

const activeTransportsStatusValues = [
  { value: 0, label: "Initiat" },
  { value: 1, label: "Incarcat" },
  {
    value: 2,
    label: "Scanare iesire din nod(cand se apasa pe 'Plecare din Nod')",
  },
  { value: 3, label: "Scanare intrare nod" },
  { value: 4, label: "Scanare descarcare marfa in nod" },
  {
    value: 5,
    label:
      "Scanare incarcare marfa in nod (programatic cand se scaneaza de incarcare prima cutie/bin)",
  },
  { value: 9, label: "Finalizat" },
];

const getActiveTransportsLabel = (status: number) => {
  const getStatus = activeTransportsStatusValues.find(
    (item) => item.value === status,
  );
  return getStatus ? getStatus.label : "";
};

export const activeTransportsTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Nr. auto",
    value: "masina",
    minW: 125,
  },
  {
    label: "Sofer",
    value: "sofer",
    minW: 125,
  },
  {
    label: "Ruta",
    value: "ruta",
    minW: 125,
  },
  {
    label: "Ruta internod",
    value: "ruta_intranod",
    minW: 125,
  },
  {
    label: "Data start",
    value: "data_start",
    minW: 125,
  },
  {
    label: "Data stop",
    value: "data_stop",
    minW: 125,
  },
  {
    label: "Status",
    value: "status",
    minW: 125,
  },
];

export const getactiveTransportsTableData = (
  data: ActiveTransportsDataInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_transport,
      crudData: item,
      data: [
        {
          title: item.masina,
        },
        {
          title: item.sofer,
        },
        {
          title: item.ruta ?? "-",
        },
        {
          title: item.ruta_intranod ?? "-",
        },
        {
          title: getDateAndTime(item.data_start) ?? "-",
        },
        {
          title: item.data_stop ? getDateAndTime(item.data_stop) : "-",
        },
        {
          badges: [
            {
              badgeText: getActiveTransportsLabel(item.status),
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });
