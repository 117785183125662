import React, { useCallback, useEffect } from "react";
import TableWidget from "../../../components/tables/TableWidget";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { getInternodeRoutes } from "../../../redux/api/expedition.api";
import {
  getInternodeRoutesTableData,
  internodeRoutesTableHeader,
} from "./tableConfigs/internodeRoutesTableConfig";
import InternodeRoutesTableActionButtons from "./TableActionsButtonsComponent/InternodeRoutesTableActionButtons";
import { clearInternodeRoutes } from "../../../redux/reducer/expedition.reducer";
import FormHeader from "../../../components/misc/FormHeader";
import { crudRoutes, expeditionRoutes } from "../../../router/routesConstants";

function InternodeRoutes() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      internodeRoutes: { data: internodeRoutes, isLoading },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const handleTableActions = useCallback(
    (action: TableActions) => {
      if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
        dispatch(
          getInternodeRoutes({
            page: action.tablePagination.page,
            perPage: action.tablePagination.rowsPerPage,
          }),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(clearInternodeRoutes());
    };
  }, [dispatch]);

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <FormHeader
          title="Rute internod"
          buttonRoute={`/${expeditionRoutes.routesOrganizationInternode}/${crudRoutes.create}`}
          buttonIcon="ADD"
          tooltipTitle="Creeaza ruta internod"
        />
      )}
      tableHeaderData={internodeRoutesTableHeader}
      tableItemsData={getInternodeRoutesTableData(internodeRoutes.data)}
      totalItems={internodeRoutes.total}
      tableLoading={isLoading}
      ActionButtonsComponent={InternodeRoutesTableActionButtons}
      handleTableActions={handleTableActions}
      showSearchForCategories={false}
      borderedRow
      withTablePagination={false}
    />
  );
}

export default InternodeRoutes;
