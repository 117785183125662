import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Formik } from "formik";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";
import FormButtons from "../../../components/misc/FormButtons";

import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  fetchAssignDriverToNewRoute,
  fetchRoutesNomenclatures,
  fetchUniqueDrivers,
} from "../../../redux/api/assign.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import DatePicker from "../../../components/misc/DatePicker";
import { dateForDatabase } from "../../../utils/dateAndTime";
import ContentCard from "../../../components/misc/ContentCard";

interface initialValuesInterface {
  selectedRoute: DropdownValue | null;
  selectedCar: DropdownValue | null;
  selectedDriver: DropdownValue | null;
  sofer_data_start: string;
  sofer_data_stop: string;
}

const initialValues: initialValuesInterface = {
  selectedRoute: null,
  selectedCar: null,
  selectedDriver: null,
  sofer_data_start: new Date().toISOString(),
  sofer_data_stop: new Date().toISOString(),
};

const formikSchema = Yup.object({
  sofer_data_start: Yup.date()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Va rugam introduceti data"),

  selectedRoute: Yup.object().required("Va rugam selectati ruta").nullable(),
  selectedCar: Yup.object().required("Va rugam selectati masina").nullable(),
  selectedDriver: Yup.object().required("Va rugam selectati sofer").nullable(),
});

function AssignDriverRoute() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [formikValues] = useState<initialValuesInterface>(initialValues);
  const [isSaving, setIsSaving] = useState(false);

  const {
    assign: {
      uniqueDrivers: {
        data: { data: uniqueDrivers },
        isLoading: isLoadingUniqueDrivers,
      },
      routesNomenclatures: {
        data: { data: routesNomenclatures },
        isLoading: isLoadingRoutesNomenclatures,
      },
    },
    fleet: {
      fleetData: {
        data: { data: fleetData },
        isLoading: isLoadingFleetData,
      },
    },
  } = useAppSelector((state) => ({
    assign: state.assign,
    expedition: state.expedition,
    fleet: state.fleet,
  }));

  useEffect(() => {
    dispatch(
      fetchUniqueDrivers({
        perPage: 10000,
      }),
    );
    dispatch(
      fetchRoutesNomenclatures({
        perPage: 10000,
      }),
    );
  }, [dispatch]);

  const handleSave = async (value: initialValuesInterface) => {
    setIsSaving(true);

    const resultAction = await dispatch(
      fetchAssignDriverToNewRoute({
        ...value,
        id_user: value.selectedDriver?.id,
        id_ruta: value.selectedRoute?.id,
        id_auto: value.selectedCar?.id,
        sofer_data_start: dateForDatabase(value.sofer_data_start),
        sofer_data_stop: dateForDatabase(value.sofer_data_stop),
      }),
    );

    if (
      checkEndpointStatus(resultAction, fetchAssignDriverToNewRoute) !==
      endpointStatus.pending
    ) {
      setIsSaving(false);
    }

    if (
      checkEndpointStatus(resultAction, fetchAssignDriverToNewRoute) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const driversNomenclaturesDropdownValues = useMemo(
    () =>
      uniqueDrivers?.map((item) => ({
        label: `${item.prenume} ${item.nume}`,
        id: item.id_user,
      })),
    [uniqueDrivers],
  );

  const routesNomenclaturesDropdownValues = useMemo(
    () =>
      routesNomenclatures?.map((item) => ({
        label: item.denumire,
        id: item.id_ruta,
      })),
    [routesNomenclatures],
  );

  const fleetNomenclaturesDropdownValues = useMemo(
    () =>
      fleetData?.map((item) => ({
        label: item.nrauto,
        id: item.id_auto,
      })),
    [fleetData],
  );

  const sortedRoutesNomenclaturesDropdownValues =
    routesNomenclaturesDropdownValues.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

  return (
    <ContentCard
      isLoading={
        isLoadingRoutesNomenclatures ||
        isLoadingFleetData ||
        isLoadingUniqueDrivers
      }
      withShadow={false}
      withPadding={false}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={formikSchema}
        onSubmit={handleSave}>
        {({
          values,
          isSubmitting,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
        }) => {
          const { selectedRoute, selectedCar, selectedDriver } = values;

          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <Dropdown
                className="my-4"
                title={"Selecteaza sofer"}
                data={driversNomenclaturesDropdownValues}
                singleValue={selectedDriver}
                setSingleValue={(value) => {
                  setFieldValue("selectedDriver", value);
                }}
                error={errors["selectedDriver"]}
                touched={touched["selectedDriver"]}
              />
              <div className="d-md-flex d-block">
                <DatePicker
                  label="Data incepere valabilitate"
                  minDate={new Date()}
                  value={values.sofer_data_start}
                  handleChangeDate={(newDate) => {
                    setFieldValue("sofer_data_start", newDate);
                  }}
                  error={errors["sofer_data_start"]}
                  touched={touched["sofer_data_start"]}
                />

                <DatePicker
                  label="Data sfarsit valabilitate"
                  minDate={new Date(values.sofer_data_start)}
                  value={values.sofer_data_stop}
                  handleChangeDate={(newDate) => {
                    setFieldValue("sofer_data_stop", newDate);
                  }}
                  error={errors["sofer_data_stop"]}
                  touched={touched["sofer_data_stop"]}
                  withVerticalSpacer
                />
              </div>

              <Dropdown
                className="my-4"
                title={"Selecteaza ruta"}
                data={sortedRoutesNomenclaturesDropdownValues}
                singleValue={selectedRoute}
                setSingleValue={(value) => {
                  setFieldValue("selectedRoute", value);
                }}
                error={errors["selectedRoute"]}
                touched={touched["selectedRoute"]}
              />
              <Dropdown
                className="my-4"
                title={"Selecteaza masina"}
                data={fleetNomenclaturesDropdownValues}
                singleValue={selectedCar}
                setSingleValue={(value) => {
                  setFieldValue("selectedCar", value);
                }}
                error={errors["selectedCar"]}
                touched={touched["selectedCar"]}
              />

              <FormButtons
                goBackLabel="Anuleaza"
                goNextLabel="Salveaza"
                handleGoBack={handleCancel}
                nextButtonDisabled={isSubmitting}
                nextButtonLoading={isSaving}
              />
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default AssignDriverRoute;
