import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { ServicesListInterface } from "../../../../../redux/interfaces/admin.interface";

const statusesTypes = [
  { value: 0, label: "Inactiv" },
  { value: 1, label: "Activ" },
];

const getStatusesTypesLabel = (status: number) => {
  const getStatus = statusesTypes.find((item) => item.value === status);
  return getStatus ? getStatus.label : "";
};

export const servicesTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Denumire",
    value: "denumire",
    minW: 125,
  },
  {
    label: "Pret",
    value: "pret",
    minW: 125,
  },
  {
    label: "Status",
    value: "status",
    minW: 125,
  },
];

export const getServicesTableData = (
  data: ServicesListInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_serviciu,
      crudData: item,
      data: [
        {
          title: item.denumire,
        },
        {
          title: item.pret + " RON",
        },
        {
          badges: [
            {
              badgeText: getStatusesTypesLabel(item.status),
              badgeColor: item.status === 1 ? "light-info" : "light-danger",
            },
          ],
        },
      ],
    };
  });
