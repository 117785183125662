import { useCallback } from "react";
import TableWidget from "../../../components/tables/TableWidget";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getWorkingRoutesNodes } from "../../../redux/api/expedition.api";
import {
  getNodesTableData,
  nodesTableHeader,
} from "./tableConfigs/expeditionNodesTableConfig";

import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { clearWorkingRoutesNodes } from "../../../redux/reducer/expedition.reducer";

// import NodesRouteTableActionButtons from "./TableActionsButtonsComponents/NodesTableActionButtons";

function NodesRoute() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      workingRoutesNodes: { data: workingRoutesNodes, isLoading },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const handleTableActions = useCallback(
    (action: TableActions) => {
      if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
        dispatch(
          getWorkingRoutesNodes({
            page: action.tablePagination.page,
            perPage: action.tablePagination.rowsPerPage,
          }),
        );
      }
    },
    [dispatch],
  );

  const handleUnmount = useCallback(() => {
    dispatch(clearWorkingRoutesNodes());
  }, [dispatch]);

  return (
    <TableWidget
      tableTitle={"Noduri"}
      tableHeaderData={nodesTableHeader}
      tableItemsData={getNodesTableData(workingRoutesNodes.data)}
      totalItems={workingRoutesNodes.total}
      tableLoading={isLoading}
      // ActionButtonsComponent={NodesRouteTableActionButtons}
      handleTableActions={handleTableActions}
      handleUnmount={handleUnmount}
      showSearchForCategories={false}
      borderedRow
    />
  );
}

export default NodesRoute;
