import React from "react";
import CustomButton from "../CustomButton";

function FormButtons({
  goBackLabel,
  goNextLabel,
  handleGoBack,
  handleGoNext,
  nextButtonDisabled,
  nextButtonLoading,
  nextButtonType = "submit",
}: {
  goBackLabel?: string;
  goNextLabel?: string;
  handleGoBack?: () => void;
  handleGoNext?: () => void;
  nextButtonDisabled?: boolean;
  nextButtonLoading?: boolean;
  nextButtonType?: "submit" | "button";
}) {
  return (
    <div className="d-flex justify-content-between mt-6">
      {goBackLabel && (
        <CustomButton
          variant="outlined"
          className="me-2"
          onClick={handleGoBack}>
          {goBackLabel}
        </CustomButton>
      )}
      {goNextLabel && (
        <CustomButton
          variant="contained"
          className="me-2"
          type={nextButtonType}
          disabled={nextButtonDisabled}
          loading={nextButtonLoading}
          onClick={handleGoNext}>
          {goNextLabel}
        </CustomButton>
      )}
    </div>
  );
}

export default FormButtons;
