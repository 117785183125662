import { memo } from "react";
import { TableItemId } from "../../../../components/tables/TableWidget";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import { expeditionRoutes } from "../../../../router/routesConstants";
import CustomButton from "../../../../components/CustomButton";
import { KTSVG } from "../../../../helpers/KTSVG";
import { InternodeRoutesDataInterface } from "../../../../redux/interfaces/expedition.interface";

function InternodeRoutesTableActionButtons({
  itemId,
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData: InternodeRoutesDataInterface;
}) {
  const goToInternodeRouteNodes = () => {
    return `/${expeditionRoutes.routesOrganizationInternode}/${itemId}/${expeditionRoutes.internodeRouteNodes}?idInternod=${itemId}&denumire=${crudData.denumire}`;
  };

  const goToOrganizeInternodeRoute = () => {
    return `/${expeditionRoutes.routesOrganizationInternode}/${expeditionRoutes.organize}?id=${itemId}&denumire=${crudData?.denumire}&cod=${crudData?.cod}`;
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        tooltipTitle="Noduri"
        className="mx-1 mb-1"
        navigate={goToInternodeRouteNodes()}>
        <KTSVG
          path="/media/svg/expedition/nodes.svg"
          className="svg-icon-1"
          fill="white"
        />
      </CustomButton>

      <CustomButton
        variant="contained"
        tooltipTitle="Organizare"
        className="mx-1 mb-1"
        navigate={goToOrganizeInternodeRoute()}>
        <KTSVG
          path="/media/svg/reception/document.svg"
          className="svg-icon-1"
          fill="white"
        />
      </CustomButton>
    </div>
  );
}

export default memo(InternodeRoutesTableActionButtons);
