import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { AssignedDriverDataInterface } from "../../../../../redux/interfaces/assign.interface";
import { dateToString } from "../../../../../utils/dateAndTime";

export const assignedDriversDataTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Nume",
    value: "nume",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Prenume",
    value: "prenume",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Ruta",
    value: "ruta",
    minW: 125,
  },
  {
    label: "Intranod",
    value: "intranod",
    minW: 200,
  },
  {
    label: "Nr. Auto",
    value: "nrauto",
    minW: 125,
  },
  {
    label: "Data inceput",
    value: "sofer_data_start",
    minW: 125,
  },
  {
    label: "Data sfarsit",
    value: "sofer_data_stop",
    minW: 125,
  },
];

export const getAssignedDriversTableData = (
  data: AssignedDriverDataInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_sofer_rute,
      crudData: item,
      data: [
        {
          title: item.nume,
        },
        {
          title: item.prenume,
        },
        {
          title: item.ruta ?? "-",
        },
        {
          title: item.intranod ?? "-",
          subtitle: item.intranod
            ? `${item.nod_start} -> ${item.nod_destinatie}`
            : "",
        },
        {
          title: item.nrauto ?? "-",
        },
        {
          title: dateToString(item.sofer_data_start, "-"),
        },
        {
          title: dateToString(item.sofer_data_stop, "-"),
        },
      ],
    };
  });
