import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";
import {
  CurrentReasonDataInterface,
  CurrentServiceDataInterface,
} from "../interfaces/admin.interface";

export const fetchUsers = createAsyncThunk(
  "/admin/users",
  async (
    transferData: {
      page: number;
      perPage: number;
      username: string;
      firstName: string;
      secondName: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { page, perPage, username, firstName, secondName } = transferData;
      const { data } = await apiRequest(
        `/showAllUsers?page=${page ?? 1}&perPage=${
          perPage ?? 5
        }&username=${username}&firstName=${firstName}&secondName=${secondName}`,
        "GET",
      );

      return data;
    } catch (err: any) {
      console.warn("/admin/users", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUserDetails = createAsyncThunk(
  "/admin/user",
  async (userId: string, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest(`/returnUser/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/user", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const postUserDetails = createAsyncThunk(
  "/admin/user",
  async (
    {
      userId,
      userDetails,
      isUpdating,
    }: {
      userId: string | null;
      userDetails: {
        userName: string;
        firstName: string;
        lastName: string;
        password: string;
        userFunction: number | null;
        node: number | null;
      };
      isUpdating?: boolean;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(`/register`, "POST", {
        userId,
        ...userDetails,
        isUpdating,
      });

      return data;
    } catch (err: any) {
      console.warn("/admin/user", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postDeleteUser = createAsyncThunk(
  "/admin/deleteUser",
  async (params: { userId: string }, { rejectWithValue, dispatch }) => {
    const { userId } = params;
    try {
      const { data } = await apiRequest(`/deleteUser/${userId}`, "GET");
      dispatch(
        fetchUsers({
          page: 1,
          perPage: 25,
          username: "",
          firstName: "",
          secondName: "",
        }),
      );
      return data;
    } catch (err: any) {
      console.warn("/admin/deleteUser", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUserPermissionsWeb = createAsyncThunk(
  "/admin/fetchUserPermissionsWeb",
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/ruteWebApp/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/fetchUserPermissionsWeb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postUserPermissionsWeb = createAsyncThunk(
  "/admin/postUserPermissionsWeb",
  async (
    transferData: {
      userId: string;
      permissions: {
        idRoute: number;
        subRoutes: {
          idRoute: number;
          isChecked: boolean;
        }[];
      }[];
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/addUserRoutes", "POST", transferData);

      return data;
    } catch (err: any) {
      console.warn("/admin/postUserPermissionsWeb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUserPermissionsMobile = createAsyncThunk(
  "/admin/fetchUserPermissionsMobile",
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/ruteMobileApp/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/fetchUserPermissionsMobile", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postUserPermissionsMobile = createAsyncThunk(
  "/admin/postUserPermissionsMobile",
  async (
    transferData: {
      userId: string;
      permissions: {
        idRoute: number;
      }[];
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "addUserRoutesMobile",
        "POST",
        transferData,
      );

      return data;
    } catch (err: any) {
      console.warn("/admin/postUserPermissionsMobile", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getReasonsNomenclature = createAsyncThunk(
  "/admin/getReasonsNomenclature",
  async (
    params: {
      page?: number;
      perPage?: number;
      denumire?: string;
      type?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/displayNomMotive", "GET", params);

      return data;
    } catch (err: any) {
      console.warn("/admin/getReasonsNomenclature", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getCurrentReason = createAsyncThunk(
  "/admin/getCurrentReason",
  async (
    params: {
      idReason: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/getCurrentReason", "GET", params);

      return data;
    } catch (err: any) {
      console.warn("/admin/getCurrentReason", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postCreateOrUpdateReason = createAsyncThunk(
  "/admin/postCreateOrUpdateReason",
  async (
    params: Partial<CurrentReasonDataInterface>,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/postCreateOrUpdateReason",
        "POST",
        params,
      );

      return data;
    } catch (err: any) {
      console.warn("/admin/postCreateOrUpdateReason", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const deleteReasonNomenclature = createAsyncThunk(
  "/admin/deleteReasonNomenclature",
  async (
    params: {
      id_motiv: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/deleteReasonNomenclature",
        "DELETE",
        params,
      );

      return data;
    } catch (err: any) {
      console.warn("/admin/deleteReasonNomenclature", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getServices = createAsyncThunk(
  "/admin/getServices",
  async (
    params: {
      page?: number;
      perPage?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/displayNomServices", "GET", params);

      return data;
    } catch (err: any) {
      console.warn("/admin/getServices", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getCurrentService = createAsyncThunk(
  "/admin/getCurrentService",
  async (
    params: {
      idServiciu?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/getCurrentService", "GET", params);

      return data;
    } catch (err: any) {
      console.warn("/admin/getCurrentService", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postCreateOrUpdateService = createAsyncThunk(
  "/admin/postCreateOrUpdateService",
  async (
    params: Partial<CurrentServiceDataInterface>,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/postCreateOrUpdateService",
        "POST",
        params,
      );

      return data;
    } catch (err: any) {
      console.warn("/admin/postCreateOrUpdateService", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postActivateDeactivateService = createAsyncThunk(
  "/admin/postActivateDeactivateService",
  async (
    params: {
      idServiciu?: string | number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/activateDeactivateService",
        "POST",
        params,
      );

      return data;
    } catch (err: any) {
      console.warn("/admin/postActivateDeactivateService", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
