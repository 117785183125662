import React from "react";

import {
  Box,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toAbsoluteUrl } from "../../../helpers/assetHelper";
import Text from "../../../styleguide/Text";
import useTableCtx from "../tableContext/useTableCtx";
import { BadgeType, TableItemType } from "../tableContext/TableContext";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 20,
    maxWidth: 500,
  },
});

function TableItem({
  tableItemData,
  tableItemId,
  crudData,
  ActionButtonsComponent,
}: {
  tableItemData: TableItemType[];
  tableItemId: string | number;
  crudData: any;
  ActionButtonsComponent?: any;
}) {
  const tableContext = useTableCtx()!;

  const { handleCheckboxClick } = tableContext;

  const renderTitle = (
    title:
      | string
      | number
      | { text: string; textStyle?: any; textClassName?: string }[],
    titleStyle: any,
    titleClassName?: string,
  ) => {
    if (typeof title === "object") {
      return title
        .filter((titleItem) => titleItem.text)
        .map((titleItem, index: number) => {
          return (
            <Text
              key={`${titleItem.text}-${index}`}
              component="p"
              variant="body1"
              gutterBottom
              style={{ ...titleStyle, ...titleItem.textStyle }}
              className={`fw-bolder text-hover-primary fs-7 mb-2 ${titleClassName} ${titleItem.textClassName}`}>
              {titleItem.text}
            </Text>
          );
        });
    }

    return (
      <Text
        component="p"
        variant="body1"
        gutterBottom
        style={titleStyle}
        className={`fw-bolder text-hover-primary fs-7  mb-2 ${titleClassName}`}>
        {title}
      </Text>
    );
  };

  const renderSubtitle = (
    subtitle:
      | string
      | number
      | { text: string; textStyle?: any; textClassName?: string }[],
    subtitleStyle: any,
    subtitleClassName?: string,
  ) => {
    if (typeof subtitle === "object") {
      return subtitle
        .filter((subtitleItem) => subtitleItem.text)
        .map((subtitleItem, index: number) => {
          return (
            <Text
              key={`${subtitleItem.text}-${index}`}
              component="p"
              variant="body1"
              gutterBottom
              style={{ ...subtitleStyle, ...subtitleItem.textStyle }}
              className={`fw-bolder text-muted fs-7 mb-2 ${subtitleClassName} ${subtitleItem.textClassName}`}>
              {subtitleItem.text}
            </Text>
          );
        });
    }

    return (
      <Text
        component="p"
        variant="body1"
        gutterBottom
        style={subtitleStyle}
        className={`fw-bolder text-muted fs-7  mb-2 ${subtitleClassName}`}>
        {subtitle}
      </Text>
    );
  };

  const renderBadges = (badges: BadgeType[]) => {
    return badges
      .filter((badge) => badge.badgeText)
      .map((badge, idx) => (
        <TooltipWrapper
          key={`${idx}-${badge.badgeText}`}
          tooltip={badge?.badgeTooltip}>
          <Text
            component="span"
            variant="body2"
            gutterBottom
            className={`badge badge-${badge?.badgeColor ?? "light-info"} mb-2 ${
              badge?.className
            } w-100`}
            style={badge?.style}>
            {badge.badgeText}
          </Text>
        </TooltipWrapper>
      ));
  };

  const renderCheckbox = (checkbox: {
    isSelected: boolean;
    id?: string | number;
  }) => {
    return (
      <Checkbox
        checked={checkbox?.isSelected || false}
        tabIndex={-1}
        disableRipple
        style={{
          transform: "scale(1.6)",
        }}
        onClick={() => handleCheckboxClick(crudData, checkbox?.id, tableItemId)}
        disabled={false}
        inputProps={{
          "aria-labelledby": `enhanced-table-checkbox-${tableItemId}`,
        }}
      />
    );
  };

  return (
    <tr className="text-hover-primary">
      {tableItemData.map((item, idx, items) => {
        return (
          <td
            key={idx}
            {...item?.config}
            className={`${
              idx === 0 ? "ps-3" : idx === items.length - 1 ? "pe-3" : ""
            }`}>
            <div className="d-flex align-items-center">
              {item?.img && (
                <div className="symbol symbol-50px me-5">
                  <img
                    src={toAbsoluteUrl(item.img)}
                    alt={item?.title?.toString()}
                    className="mr-3"
                  />
                </div>
              )}
              <div className="d-flex justify-content-start flex-column">
                {item?.title && renderTitle(item.title, item.titleStyle)}
                {item?.subtitle &&
                  renderSubtitle(item.subtitle, item.subtitleStyle)}
                {item?.badges &&
                  item?.badges?.length > 0 &&
                  renderBadges(item.badges)}
              </div>
              {item?.checkbox && renderCheckbox(item?.checkbox)}
            </div>
          </td>
        );
      })}
      <td className="text-end ml-auto rounded-end">
        {ActionButtonsComponent && (
          <ActionButtonsComponent
            itemId={tableItemId}
            crudData={crudData}
            tableItemData={tableItemData}
            tableContext={tableContext}
          />
        )}
      </td>
    </tr>
  );
}

const TooltipWrapper = ({
  children,
  tooltip,
}: {
  children: React.ReactNode;
  tooltip?: React.ReactNode | string;
}) => {
  if (tooltip) {
    return (
      <CustomWidthTooltip arrow title={tooltip}>
        <Box>{children}</Box>
      </CustomWidthTooltip>
    );
  }
  return <div>{children}</div>;
};

export default TableItem;
