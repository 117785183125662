import { useCallback, useMemo } from "react";
import TableWidget from "../../../components/tables/TableWidget";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { fetchBinHistory } from "../../../redux/api/expedition.api";
import {
  binHistoryTableHeader,
  getBinTableData,
} from "./tableConfigs/binHistoryTableConfig";
import BinHistoryActionButton from "./ActionButtons/BinHistoryActionButtons";

function BinHistoryTable() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      binHistory: {
        data: { data: dataBin, total: totalBin },
        isLoading: isLoadingBin,
      },
      workingRoutesNodes: {
        data: { data: clientsNodesData },
      },
      clients: { data: clientsData },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const getHistoryTableHeaderNew = useCallback(
    (initialTableData: any[]) => {
      return initialTableData.map((item) => {
        if (item.value === "nodDestinatie") {
          const nodesDropdown = clientsNodesData?.map(
            ({ nodNume, id_nod }) => ({
              label: nodNume,
              value: id_nod,
            }),
          );
          return {
            withFilter: [{ label: "Toate", value: 0 }, ...nodesDropdown],
            ...item,
          };
        }
        if (item.value === "client") {
          const clientsDropdown = clientsData?.data.map(
            ({ denumireClient, idClient }) => ({
              label: denumireClient,
              value: idClient || 0,
            }),
          );
          return {
            withFilter: [{ label: "Toate", value: "all" }, ...clientsDropdown],
            ...item,
          };
        }

        return item;
      });
    },
    [clientsData, clientsNodesData],
  );

  const handleBinTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch &&
      action.tableFilter &&
      action.tableFilter.client
    ) {
      dispatch(
        fetchBinHistory({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          idClient: action.tableFilter.client,
          nodDestinatie: action.tableFilter.nodDestinatie,
        }),
      );
    }
  };

  const binHistoryTableHeaderNew = useMemo(
    () => getHistoryTableHeaderNew(binHistoryTableHeader),
    [getHistoryTableHeaderNew],
  );

  return (
    <TableWidget
      tableHeaderData={binHistoryTableHeaderNew}
      tableItemsData={getBinTableData(dataBin)}
      totalItems={totalBin}
      tableLoading={isLoadingBin}
      handleTableActions={handleBinTableActions}
      ActionButtonsComponent={(props: any) => (
        <BinHistoryActionButton {...props} />
      )}
      borderedRow
    />
  );
}

export default BinHistoryTable;
