import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";

import { useAppDispatch } from "../../../../../redux/hooks";
import { AssignedDriverDataInterface } from "../../../../../redux/interfaces/assign.interface";
import { fetchDeactivateOrActivateRoute } from "../../../../../redux/api/assign.api";

function AssignDriverActionButton({
  itemId,
  crudData,
}: {
  itemId: string;
  crudData: AssignedDriverDataInterface;
}) {
  const dispatch = useAppDispatch();

  const handleDeactivateDriver = () => {
    dispatch(
      fetchDeactivateOrActivateRoute({
        id_sofer_rute: itemId,
        activ: 2,
      }),
    );
  };

  return (
    <div>
      {(crudData.id_ruta || crudData.id_ruta_intranod) && (
        <CustomButton
          variant="contained"
          color="error"
          className="mt-1 me-1"
          tooltipTitle="Dezactivare ruta"
          withConfirmationModal={{
            modalTitle: "Doriti sa dezactivati ruta?",
            modalSubtitle: `${crudData.nume} ${crudData.prenume} -> ${
              crudData.ruta || crudData.intranod
            }`,
            modalRightButtonText: "Da",
            modalLeftButtonText: "Nu",
            modalRightButtonOnClick: handleDeactivateDriver,
          }}>
          <SvgIcon type="CLOSE_ICON" />
        </CustomButton>
      )}
    </div>
  );
}

export default AssignDriverActionButton;
